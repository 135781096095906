import { useCallback, useState, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { toast } from '@/components/ui/use-toast.ts';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import MultipleSelector, { Option } from '@/components/ui/multiple-selector';
import { useQuery, useMutation } from '@apollo/client';
import {
  UsersDocument,
  NasListDocument,
  UpdateNasDocument,
  RemoveNasDocument,
  UserModel,
  NasModel,
} from '@@graphql';
import { Separator } from '@/components/ui/separator';
import CheckNasConnectionButton from './check-nas-connection-button';

const columns = [
  {
    label: '名稱',
    name: 'name',
    type: 'text',
  },
  {
    label: '主機網址',
    name: 'host',
    type: 'text',
  },
  {
    label: '帳號',
    name: 'username',
    type: 'text',
  },
  {
    label: '密碼',
    name: 'password',
    type: 'password',
  },
  {
    label: '埠號',
    name: 'port',
    type: 'number',
  },
];

const FormSchema = z.object({
  name: z.string().min(1, { message: '名稱不能為空' }),
  host: z
    .string()
    .min(1, { message: '主機網址不能為空' })
    .refine(value => value.startsWith('http://') || value.startsWith('https://'), {
      message: '主機網址必須以 http:// 或 https:// 開頭',
    }),
  username: z.string().min(1, { message: '帳號不能為空' }),
  password: z.string().min(1, { message: '密碼不能為空' }),
  port: z.coerce.number().min(1, { message: '埠號不能為空' }),
  userIds: z.array(z.string()).optional(),
});

type EditNasDialogProps = {
  id: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  defaultNas: NasModel;
};

const EditNasDialog = ({ id, open, setOpen, defaultNas }: EditNasDialogProps) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<Option[]>([]);
  const [isEditSaving, setIsEditSaving] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const { handleError } = useErrorHandler({});

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: '',
      host: '',
      username: '',
      password: '',
      port: 443,
      userIds: [],
    },
    mode: 'onBlur',
  });
  const {
    control,
    reset,
    clearErrors,
    watch,
    formState: { dirtyFields },
  } = form;

  const { host, username, password, port } = watch();

  const [updateNas] = useMutation(UpdateNasDocument, {
    refetchQueries: [{ query: NasListDocument }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setIsEditSaving(false);
      toast({
        title: '編輯成功',
      });
      setOpen(false);
      form.reset();
    },
  });

  const [removeNas] = useMutation(RemoveNasDocument, {
    refetchQueries: [{ query: NasListDocument }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setIsRemoveLoading(false);
      toast({
        title: '刪除成功',
      });
      setOpen(false);
      form.reset();
    },
  });

  const { data: usersData } = useQuery(UsersDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {},
    skip: !open,
  });

  const userOptions = useMemo(
    () =>
      usersData?.users?.map((user: UserModel) => ({
        label: `${user.name} (${user.email})`,
        value: user.id,
      })) || [],
    [usersData],
  );

  const availableUserOptions = useMemo(
    () =>
      userOptions.filter(
        (option: Option) =>
          !selectedUsers.some(selectedUser => selectedUser.value === option.value),
      ),
    [userOptions, selectedUsers],
  );

  useEffect(() => {
    if (defaultNas) {
      reset({
        name: defaultNas.name,
        host: defaultNas.host,
        username: defaultNas.username,
        password: defaultNas.password,
        port: defaultNas.port || 443,
      });

      if (defaultNas?.users && defaultNas?.users?.length !== 0) {
        const defaultSelectedUsers =
          defaultNas.users.map(user => ({
            label: `${user.name} (${user.email})`,
            value: user.id,
          })) || [];
        setSelectedUsers(defaultSelectedUsers);
      }
    }
  }, [defaultNas, reset]);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        reset();
        clearErrors();
      }
      setOpen(open);
    },
    [clearErrors, reset, setOpen],
  );

  const onSubmit = useCallback(
    async (data: z.infer<typeof FormSchema>) => {
      const changedData = Object.keys(dirtyFields).reduce<Record<string, unknown>>(
        (changedFields, key) => {
          const typedKey = key as keyof z.infer<typeof FormSchema>;
          changedFields[typedKey] = data[typedKey];
          return changedFields;
        },
        {},
      ) as Partial<z.infer<typeof FormSchema>>;

      try {
        setIsEditSaving(true);
        await updateNas({
          variables: {
            id,
            ...changedData,
          },
        });
      } catch (err) {
        handleError(err);
      }
    },
    [dirtyFields, updateNas, id, handleError],
  );

  const handleRemoveNas = useCallback(async () => {
    try {
      setIsRemoveLoading(true);
      await removeNas({ variables: { id } });
    } catch (err) {
      handleError(err);
    }
  }, [removeNas, id, handleError]);

  return (
    <>
      <Dialog open={open} onOpenChange={handleOpenChange}>
        <DialogContent className="sm:max-w-[400px]">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <DialogHeader>
                <DialogTitle>編輯 NAS</DialogTitle>
              </DialogHeader>
              <div className="grid gap-4 mt-4 py-4">
                {columns.map(({ label, name, type }) => (
                  <FormField
                    key={name}
                    control={control}
                    name={name as keyof z.infer<typeof FormSchema>}
                    render={({ field }) => (
                      <FormItem>
                        <div className="inline-flex items-center w-full">
                          <FormLabel className="w-[110px]">{label}</FormLabel>
                          <FormControl>
                            <Input
                              className="w-[230px] h-[32px]"
                              type={type}
                              {...field}
                            />
                          </FormControl>
                        </div>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}
                <Separator />
                <FormField
                  control={control}
                  name="userIds"
                  render={({ field }) => (
                    <FormItem>
                      <div className="items-center w-full">
                        <FormLabel className="w-[110px]">通知名單</FormLabel>
                        <FormControl>
                          <MultipleSelector
                            {...field}
                            className="w-[350px] min-h-[80px] bg-white mt-2"
                            onChange={newSelectedUsers => {
                              setSelectedUsers(newSelectedUsers);
                              field.onChange(newSelectedUsers.map(user => user.value));
                            }}
                            value={selectedUsers}
                            hideClearAllButton
                            hidePlaceholderWhenSelected
                            placeholder="請選擇通知名單"
                            options={availableUserOptions}
                            emptyIndicator={
                              <p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">
                                沒有使用者
                              </p>
                            }
                          />
                        </FormControl>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <DialogFooter className="flex justify-between sm:justify-between">
                <Button
                  type="button"
                  variant="destructive"
                  onClick={() => setOpenDeleteDialog(true)}
                >
                  刪除 NAS
                </Button>
                <div className="flex gap-2">
                  <CheckNasConnectionButton
                    host={host}
                    username={username}
                    password={password}
                    port={port}
                  />
                  <Button type="submit" loading={isEditSaving}>
                    儲存
                  </Button>
                </div>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
      <Dialog open={openDeleteDialog} onOpenChange={setOpenDeleteDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>刪除 NAS</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4 text-sm">
            您確定要刪除 {defaultNas?.name} 的 NAS 設定嗎？
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" type="button">
                取消
              </Button>
            </DialogClose>
            <Button type="submit" onClick={handleRemoveNas} loading={isRemoveLoading}>
              確定
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditNasDialog;

import { type NavItem } from '@/types';
import { cn } from '@/lib/utils';
import { useSidebar } from '@/hooks/useSidebar';
import { Button, buttonVariants } from '@/components/ui/button';
import { useEffect, useState } from 'react';
import { Separator } from '../ui/separator';
import { FolderClose, Setting, SignOut, TrashCan } from '../icons';
import { Progress } from '../ui/progress';
import { Link, useNavigate, useRouterState } from '@tanstack/react-router';
import { PanelLeftClose, HardDrive } from 'lucide-react';
import { formatFileSize } from '@/utils/file.ts';
import { useStore } from '@/store';
import { useNasStore } from '@/store/nas';
import { useAuth } from '@/auth.tsx';

interface SideNavProps {
  items: NavItem[];
  setOpen?: (open: boolean) => void;
  className?: string;
  setStatus?: (status: boolean) => void;
}

const TrashButton = ({
  isOpen,
  className,
}: {
  isOpen: boolean;
  className?: string;
}) => {
  return (
    <a
      href="/trash"
      className={cn(
        buttonVariants({ variant: 'ghost' }),
        'group relative flex h-12 justify-start font-normal text-[#120D6E] bg-[#FAFAFC] hover:text-[#5346E6] hover:bg-[#EDF0FD]',
      )}
    >
      <TrashCan />
      <span
        className={cn('absolute left-12 text-sm duration-200', !isOpen && className)}
      >
        資源回收桶
      </span>
    </a>
  );
};

const UsageDisplay = () => {
  const getConfig = useStore.companyConfig(state => state.getConfigValue);
  const usedSize = parseInt(getConfig('usedSize'));
  const totalSize = getConfig('isMain')
    ? getConfig('usageLimit').storage * 1024 * 1024 * 1024
    : 0;
  let usagePercentage = 0;
  if (usedSize && usedSize > 0) {
    usagePercentage = Math.min(Math.max(0, (usedSize / totalSize) * 100), 100);
  }
  let formattedUsedSize = formatFileSize(usedSize);
  if (formattedUsedSize === '--') {
    formattedUsedSize = '0';
  }
  return (
    <>
      <Progress value={usagePercentage} className="h-1" />
      <p className="my-3 text-xs text-neutral-400">
        已使用 {formattedUsedSize} / {formatFileSize(totalSize)}
      </p>
    </>
  );
};

export function SideNav({ items, setOpen, className, setStatus }: SideNavProps) {
  const { user } = useAuth();
  const nasList = useNasStore(state => state.nasList);
  const routerState = useRouterState();
  const navigate = useNavigate();
  const currentPathname = routerState.location.pathname;
  const { isOpen, toggle } = useSidebar();
  const [openItem, setOpenItem] = useState('');
  const [lastOpenItem, setLastOpenItem] = useState('');

  const handleToggle = () => {
    if (!setStatus) return;
    setStatus(true);
    toggle();
    setTimeout(() => setStatus(false), 500);
  };

  useEffect(() => {
    if (isOpen) {
      setOpenItem(lastOpenItem);
    } else {
      setLastOpenItem(openItem);
      setOpenItem('');
    }
  }, [isOpen, lastOpenItem, openItem]);

  return (
    <>
      <nav className="space-y-2">
        <a
          onClick={async e => {
            e.preventDefault();
            if (setOpen) setOpen(false);
            await navigate({
              to: '/',
            });
          }}
          className={cn(
            buttonVariants({ variant: 'ghost' }),
            'group relative flex h-12 justify-start font-normal text-[#120D6E] bg-[#FAFAFC] hover:text-[#5346E6] hover:bg-[#EDF0FD]',
            currentPathname === '/files' &&
              'text-white bg-[#5346E6] hover:text-white hover:bg-[#5346E6]',
          )}
        >
          <FolderClose />
          <div
            className={cn(
              'absolute left-12 text-base duration-200 w-full cursor-pointer h-12 leading-[48px]',
              !isOpen && className,
            )}
          >
            Home
          </div>
        </a>
        <Separator />
        {nasList.length > 0 && (
          <>
            <div className="flex gap-1 flex-col h-auto max-h-[110px] mt-0 overflow-y-scroll">
              {nasList.map(nas => (
                <a
                  key={nas.id}
                  className={cn(
                    buttonVariants({ variant: 'ghost' }),
                    'group relative flex h-10 justify-start font-normal text-[#120D6E] bg-[#FAFAFC] hover:text-[#5346E6] hover:bg-[#EDF0FD]',
                  )}
                  href={`/nas/${nas.id}`}
                >
                  <HardDrive strokeWidth={1.5} />
                  <div
                    className={cn(
                      'absolute left-12 text-sm duration-200 w-full cursor-pointer h-10 leading-[40px]',
                    )}
                  >
                    {nas.name}
                  </div>
                </a>
              ))}
            </div>
            <Separator style={{ marginBottom: '16px' }} />
          </>
        )}
        <div className="flex gap-1 flex-col h-[360px] overflow-y-scroll">
          {items.map(item => (
            <a
              key={item.title}
              href={item.href}
              aria-label={item.title}
              onClick={async e => {
                e.preventDefault();
                if (setOpen) setOpen(false);
                await navigate({
                  to: '/folders/$folderId',
                  params: {
                    folderId: item.href,
                  },
                });
              }}
              className={cn(
                buttonVariants({ variant: 'ghost' }),
                'group relative flex h-12 justify-start font-normal text-[#120D6E] bg-[#FAFAFC] hover:text-[#5346E6] hover:bg-[#EDF0FD]',
                currentPathname === item.href &&
                  'text-white bg-[#5346E6] hover:text-white hover:bg-[#5346E6]',
              )}
            >
              {item.icon}
              <span
                className={cn(
                  'absolute left-12 text-sm duration-200',
                  !isOpen && className,
                )}
              >
                {item.title}
              </span>
            </a>
          ))}
          <Separator style={{ marginBottom: '4px' }} />
          <TrashButton isOpen={isOpen} className={className} />
        </div>
      </nav>
      <Separator className="my-4" />
      <Button
        className="flex justify-start gap-2 w-full font-normal text-primary"
        variant="ghost"
        onClick={handleToggle}
      >
        <PanelLeftClose
          strokeWidth={1.2}
          scale={15}
          className={cn('text-primary', !isOpen && 'rotate-180')}
        />
        {isOpen && <p>Hide Menu</p>}
      </Button>
      <Separator className="my-4" />
      <UsageDisplay />
      <Separator className="my-4" />
      {isOpen && (
        <div className="w-full flex">
          {user?.isAdmin && (
            <Link className="w-1/2 inline-block" to="/settings">
              <Button className="font-normal text-primary-dark" variant="ghost">
                <Setting />
                <p className="ml-2">設定</p>
              </Button>
            </Link>
          )}
          <a
            className={`${user?.isAdmin ? 'w-1/2' : 'w-full'} inline-block`}
            onClick={async e => {
              e.preventDefault();
              localStorage.removeItem('accessToken');
              window.location.href = '/login';
            }}
          >
            <Button
              className={`${
                user?.isAdmin ? '' : 'w-full'
              } font-normal text-primary-dark`}
              variant="ghost"
              onClick={async e => {
                e.preventDefault();
                localStorage.removeItem('accessToken');
                window.location.href = '/login';
              }}
            >
              <SignOut />
              {isOpen && <p className="ml-2">登出</p>}
            </Button>
          </a>
        </div>
      )}
    </>
  );
}

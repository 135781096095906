import Lottie from 'react-lottie';
import animationData from '@/lotties/empty-data.json';
import { getAnimationConfig } from '@/lib/utils';
import { cn } from '@/lib/utils';

const EmptyData = ({
  title,
  wrapperHeight,
}: {
  title?: string;
  wrapperHeight?: string;
}) => {
  return (
    <div
      className={cn(
        'flex justify-center items-center flex-col w-full]',
        wrapperHeight || 'h-[calc(100vh-300px)',
      )}
    >
      <Lottie
        options={getAnimationConfig({ animationData })}
        height={250}
        width={250}
      />
      <p className="text-neutral-500 mt-[-40px]">{title || '目前沒有資料'}</p>
    </div>
  );
};

export default EmptyData;

import { createFileRoute } from '@tanstack/react-router';
import NasFileView from '@/pages/nas-file-view';
import { useQuery } from '@apollo/client';
import { NasFileDocument } from '@@graphql';

export const Route = createFileRoute('/nas/$nasId/file/$nasFileId')({
  component: NasFileComponent,
});

function NasFileComponent() {
  const { nasId, nasFileId } = Route.useParams();

  const { data, error, loading } = useQuery(NasFileDocument, {
    variables: { nasId, nasFileId },
    fetchPolicy: 'network-only',
  });

  if (loading) return null;
  if (error) return <div>Error: {error.message}</div>;
  return <NasFileView data={data?.nasFile} />;
}

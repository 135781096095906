import { useLazyQuery } from '@apollo/client';
import { CheckNasConnectionDocument } from '@@graphql';
import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/use-toast';

type CheckNasConnectionButtonProps = {
  host: string;
  username: string;
  password: string;
  port: number;
};

const CheckNasConnectionButton = ({
  host,
  username,
  password,
  port,
}: CheckNasConnectionButtonProps) => {
  const [checkNasConnection, { loading }] = useLazyQuery(CheckNasConnectionDocument, {
    onCompleted: data => {
      if (data?.checkNasConnection?.success) {
        toast({
          title: '連線成功',
          duration: 1500,
        });
      } else {
        toast({
          title: '連線失敗',
          duration: 1500,
        });
      }
    },
    onError: () => {
      toast({
        title: '連線失敗',
        duration: 1500,
      });
    },
  });

  return (
    <Button
      variant="outline"
      type="button"
      loading={loading}
      disabled={loading || !host || !username || !password || !port}
      onClick={() =>
        checkNasConnection({ variables: { host, username, password, port } })
      }
    >
      測試連線
    </Button>
  );
};

export default CheckNasConnectionButton;

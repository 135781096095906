/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SelectCompanyImport } from './routes/select-company'
import { Route as ResetPasswordImport } from './routes/reset-password'
import { Route as OtpVerificationImport } from './routes/otp-verification'
import { Route as OnboardingImport } from './routes/onboarding'
import { Route as LoginImport } from './routes/login'
import { Route as ForgotPasswordImport } from './routes/forgot-password'
import { Route as EmailVerificationImport } from './routes/email-verification'
import { Route as LayoutImport } from './routes/_layout'
import { Route as LayoutIndexImport } from './routes/_layout/index'
import { Route as FilesFileIdImport } from './routes/files/$fileId'
import { Route as LayoutSearchImport } from './routes/_layout/search'
import { Route as LayoutProfileAuthImport } from './routes/_layout/profile-auth'
import { Route as LayoutFileMetricsRecordsImport } from './routes/_layout/file-metrics-records'
import { Route as LayoutFileHistoryRecordsImport } from './routes/_layout/file-history-records'
import { Route as LayoutAuditReportsImport } from './routes/_layout/audit-reports'
import { Route as LayoutTrashIndexImport } from './routes/_layout/trash/index'
import { Route as LayoutSettingsIndexImport } from './routes/_layout/settings/index'
import { Route as LayoutFoldersIndexImport } from './routes/_layout/folders/index'
import { Route as LayoutFilesIndexImport } from './routes/_layout/files/index'
import { Route as LayoutTrashFileIdImport } from './routes/_layout/trash/$fileId'
import { Route as LayoutSettingsWhitelistSettingsImport } from './routes/_layout/settings/whitelist-settings'
import { Route as LayoutSettingsWaterMarkSettingsImport } from './routes/_layout/settings/water-mark-settings'
import { Route as LayoutSettingsUserSettingsImport } from './routes/_layout/settings/user-settings'
import { Route as LayoutSettingsSensitiveWordSettingsImport } from './routes/_layout/settings/sensitive-word-settings'
import { Route as LayoutSettingsRoleSettingsImport } from './routes/_layout/settings/role-settings'
import { Route as LayoutSettingsNasSettingsImport } from './routes/_layout/settings/nas-settings'
import { Route as LayoutSettingsKeywordsImport } from './routes/_layout/settings/keywords'
import { Route as LayoutSettingsImportExportImport } from './routes/_layout/settings/import-export'
import { Route as LayoutSettingsDepartmentSettingsImport } from './routes/_layout/settings/department-settings'
import { Route as LayoutSettingsAdminSettingsImport } from './routes/_layout/settings/admin-settings'
import { Route as LayoutProfileIntegrationImport } from './routes/_layout/profile/integration'
import { Route as LayoutProfileChangeCompanyImport } from './routes/_layout/profile/change-company'
import { Route as LayoutProfileBasicSettingsImport } from './routes/_layout/profile/basic-settings'
import { Route as LayoutProfile2faSettingsImport } from './routes/_layout/profile/2fa-settings'
import { Route as LayoutFoldersFolderIdImport } from './routes/_layout/folders/$folderId'
import { Route as LayoutConfidentialFilesDepartmentIdImport } from './routes/_layout/confidential-files/$departmentId'
import { Route as LayoutNasNasIdIndexImport } from './routes/_layout/nas/$nasId/index'
import { Route as NasNasIdFileNasFileIdImport } from './routes/nas/$nasId/file/$nasFileId'
import { Route as LayoutSettingsDepartmentsDepartmentIdImport } from './routes/_layout/settings/departments/$departmentId'
import { Route as LayoutNasNasIdSearchImport } from './routes/_layout/nas/$nasId/search'

// Create/Update Routes

const SelectCompanyRoute = SelectCompanyImport.update({
  id: '/select-company',
  path: '/select-company',
  getParentRoute: () => rootRoute,
} as any)

const ResetPasswordRoute = ResetPasswordImport.update({
  id: '/reset-password',
  path: '/reset-password',
  getParentRoute: () => rootRoute,
} as any)

const OtpVerificationRoute = OtpVerificationImport.update({
  id: '/otp-verification',
  path: '/otp-verification',
  getParentRoute: () => rootRoute,
} as any)

const OnboardingRoute = OnboardingImport.update({
  id: '/onboarding',
  path: '/onboarding',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const ForgotPasswordRoute = ForgotPasswordImport.update({
  id: '/forgot-password',
  path: '/forgot-password',
  getParentRoute: () => rootRoute,
} as any)

const EmailVerificationRoute = EmailVerificationImport.update({
  id: '/email-verification',
  path: '/email-verification',
  getParentRoute: () => rootRoute,
} as any)

const LayoutRoute = LayoutImport.update({
  id: '/_layout',
  getParentRoute: () => rootRoute,
} as any)

const LayoutIndexRoute = LayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => LayoutRoute,
} as any)

const FilesFileIdRoute = FilesFileIdImport.update({
  id: '/files/$fileId',
  path: '/files/$fileId',
  getParentRoute: () => rootRoute,
} as any)

const LayoutSearchRoute = LayoutSearchImport.update({
  id: '/search',
  path: '/search',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutProfileAuthRoute = LayoutProfileAuthImport.update({
  id: '/profile-auth',
  path: '/profile-auth',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutFileMetricsRecordsRoute = LayoutFileMetricsRecordsImport.update({
  id: '/file-metrics-records',
  path: '/file-metrics-records',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutFileHistoryRecordsRoute = LayoutFileHistoryRecordsImport.update({
  id: '/file-history-records',
  path: '/file-history-records',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutAuditReportsRoute = LayoutAuditReportsImport.update({
  id: '/audit-reports',
  path: '/audit-reports',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutTrashIndexRoute = LayoutTrashIndexImport.update({
  id: '/trash/',
  path: '/trash/',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutSettingsIndexRoute = LayoutSettingsIndexImport.update({
  id: '/settings/',
  path: '/settings/',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutFoldersIndexRoute = LayoutFoldersIndexImport.update({
  id: '/folders/',
  path: '/folders/',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutFilesIndexRoute = LayoutFilesIndexImport.update({
  id: '/files/',
  path: '/files/',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutTrashFileIdRoute = LayoutTrashFileIdImport.update({
  id: '/trash/$fileId',
  path: '/trash/$fileId',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutSettingsWhitelistSettingsRoute =
  LayoutSettingsWhitelistSettingsImport.update({
    id: '/settings/whitelist-settings',
    path: '/settings/whitelist-settings',
    getParentRoute: () => LayoutRoute,
  } as any)

const LayoutSettingsWaterMarkSettingsRoute =
  LayoutSettingsWaterMarkSettingsImport.update({
    id: '/settings/water-mark-settings',
    path: '/settings/water-mark-settings',
    getParentRoute: () => LayoutRoute,
  } as any)

const LayoutSettingsUserSettingsRoute = LayoutSettingsUserSettingsImport.update(
  {
    id: '/settings/user-settings',
    path: '/settings/user-settings',
    getParentRoute: () => LayoutRoute,
  } as any,
)

const LayoutSettingsSensitiveWordSettingsRoute =
  LayoutSettingsSensitiveWordSettingsImport.update({
    id: '/settings/sensitive-word-settings',
    path: '/settings/sensitive-word-settings',
    getParentRoute: () => LayoutRoute,
  } as any)

const LayoutSettingsRoleSettingsRoute = LayoutSettingsRoleSettingsImport.update(
  {
    id: '/settings/role-settings',
    path: '/settings/role-settings',
    getParentRoute: () => LayoutRoute,
  } as any,
)

const LayoutSettingsNasSettingsRoute = LayoutSettingsNasSettingsImport.update({
  id: '/settings/nas-settings',
  path: '/settings/nas-settings',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutSettingsKeywordsRoute = LayoutSettingsKeywordsImport.update({
  id: '/settings/keywords',
  path: '/settings/keywords',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutSettingsImportExportRoute = LayoutSettingsImportExportImport.update(
  {
    id: '/settings/import-export',
    path: '/settings/import-export',
    getParentRoute: () => LayoutRoute,
  } as any,
)

const LayoutSettingsDepartmentSettingsRoute =
  LayoutSettingsDepartmentSettingsImport.update({
    id: '/settings/department-settings',
    path: '/settings/department-settings',
    getParentRoute: () => LayoutRoute,
  } as any)

const LayoutSettingsAdminSettingsRoute =
  LayoutSettingsAdminSettingsImport.update({
    id: '/settings/admin-settings',
    path: '/settings/admin-settings',
    getParentRoute: () => LayoutRoute,
  } as any)

const LayoutProfileIntegrationRoute = LayoutProfileIntegrationImport.update({
  id: '/profile/integration',
  path: '/profile/integration',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutProfileChangeCompanyRoute = LayoutProfileChangeCompanyImport.update(
  {
    id: '/profile/change-company',
    path: '/profile/change-company',
    getParentRoute: () => LayoutRoute,
  } as any,
)

const LayoutProfileBasicSettingsRoute = LayoutProfileBasicSettingsImport.update(
  {
    id: '/profile/basic-settings',
    path: '/profile/basic-settings',
    getParentRoute: () => LayoutRoute,
  } as any,
)

const LayoutProfile2faSettingsRoute = LayoutProfile2faSettingsImport.update({
  id: '/profile/2fa-settings',
  path: '/profile/2fa-settings',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutFoldersFolderIdRoute = LayoutFoldersFolderIdImport.update({
  id: '/folders/$folderId',
  path: '/folders/$folderId',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutConfidentialFilesDepartmentIdRoute =
  LayoutConfidentialFilesDepartmentIdImport.update({
    id: '/confidential-files/$departmentId',
    path: '/confidential-files/$departmentId',
    getParentRoute: () => LayoutRoute,
  } as any)

const LayoutNasNasIdIndexRoute = LayoutNasNasIdIndexImport.update({
  id: '/nas/$nasId/',
  path: '/nas/$nasId/',
  getParentRoute: () => LayoutRoute,
} as any)

const NasNasIdFileNasFileIdRoute = NasNasIdFileNasFileIdImport.update({
  id: '/nas/$nasId/file/$nasFileId',
  path: '/nas/$nasId/file/$nasFileId',
  getParentRoute: () => rootRoute,
} as any)

const LayoutSettingsDepartmentsDepartmentIdRoute =
  LayoutSettingsDepartmentsDepartmentIdImport.update({
    id: '/settings/departments/$departmentId',
    path: '/settings/departments/$departmentId',
    getParentRoute: () => LayoutRoute,
  } as any)

const LayoutNasNasIdSearchRoute = LayoutNasNasIdSearchImport.update({
  id: '/nas/$nasId/search',
  path: '/nas/$nasId/search',
  getParentRoute: () => LayoutRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_layout': {
      id: '/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LayoutImport
      parentRoute: typeof rootRoute
    }
    '/email-verification': {
      id: '/email-verification'
      path: '/email-verification'
      fullPath: '/email-verification'
      preLoaderRoute: typeof EmailVerificationImport
      parentRoute: typeof rootRoute
    }
    '/forgot-password': {
      id: '/forgot-password'
      path: '/forgot-password'
      fullPath: '/forgot-password'
      preLoaderRoute: typeof ForgotPasswordImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/onboarding': {
      id: '/onboarding'
      path: '/onboarding'
      fullPath: '/onboarding'
      preLoaderRoute: typeof OnboardingImport
      parentRoute: typeof rootRoute
    }
    '/otp-verification': {
      id: '/otp-verification'
      path: '/otp-verification'
      fullPath: '/otp-verification'
      preLoaderRoute: typeof OtpVerificationImport
      parentRoute: typeof rootRoute
    }
    '/reset-password': {
      id: '/reset-password'
      path: '/reset-password'
      fullPath: '/reset-password'
      preLoaderRoute: typeof ResetPasswordImport
      parentRoute: typeof rootRoute
    }
    '/select-company': {
      id: '/select-company'
      path: '/select-company'
      fullPath: '/select-company'
      preLoaderRoute: typeof SelectCompanyImport
      parentRoute: typeof rootRoute
    }
    '/_layout/audit-reports': {
      id: '/_layout/audit-reports'
      path: '/audit-reports'
      fullPath: '/audit-reports'
      preLoaderRoute: typeof LayoutAuditReportsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/file-history-records': {
      id: '/_layout/file-history-records'
      path: '/file-history-records'
      fullPath: '/file-history-records'
      preLoaderRoute: typeof LayoutFileHistoryRecordsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/file-metrics-records': {
      id: '/_layout/file-metrics-records'
      path: '/file-metrics-records'
      fullPath: '/file-metrics-records'
      preLoaderRoute: typeof LayoutFileMetricsRecordsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/profile-auth': {
      id: '/_layout/profile-auth'
      path: '/profile-auth'
      fullPath: '/profile-auth'
      preLoaderRoute: typeof LayoutProfileAuthImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/search': {
      id: '/_layout/search'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof LayoutSearchImport
      parentRoute: typeof LayoutImport
    }
    '/files/$fileId': {
      id: '/files/$fileId'
      path: '/files/$fileId'
      fullPath: '/files/$fileId'
      preLoaderRoute: typeof FilesFileIdImport
      parentRoute: typeof rootRoute
    }
    '/_layout/': {
      id: '/_layout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof LayoutIndexImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/confidential-files/$departmentId': {
      id: '/_layout/confidential-files/$departmentId'
      path: '/confidential-files/$departmentId'
      fullPath: '/confidential-files/$departmentId'
      preLoaderRoute: typeof LayoutConfidentialFilesDepartmentIdImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/folders/$folderId': {
      id: '/_layout/folders/$folderId'
      path: '/folders/$folderId'
      fullPath: '/folders/$folderId'
      preLoaderRoute: typeof LayoutFoldersFolderIdImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/profile/2fa-settings': {
      id: '/_layout/profile/2fa-settings'
      path: '/profile/2fa-settings'
      fullPath: '/profile/2fa-settings'
      preLoaderRoute: typeof LayoutProfile2faSettingsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/profile/basic-settings': {
      id: '/_layout/profile/basic-settings'
      path: '/profile/basic-settings'
      fullPath: '/profile/basic-settings'
      preLoaderRoute: typeof LayoutProfileBasicSettingsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/profile/change-company': {
      id: '/_layout/profile/change-company'
      path: '/profile/change-company'
      fullPath: '/profile/change-company'
      preLoaderRoute: typeof LayoutProfileChangeCompanyImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/profile/integration': {
      id: '/_layout/profile/integration'
      path: '/profile/integration'
      fullPath: '/profile/integration'
      preLoaderRoute: typeof LayoutProfileIntegrationImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/settings/admin-settings': {
      id: '/_layout/settings/admin-settings'
      path: '/settings/admin-settings'
      fullPath: '/settings/admin-settings'
      preLoaderRoute: typeof LayoutSettingsAdminSettingsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/settings/department-settings': {
      id: '/_layout/settings/department-settings'
      path: '/settings/department-settings'
      fullPath: '/settings/department-settings'
      preLoaderRoute: typeof LayoutSettingsDepartmentSettingsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/settings/import-export': {
      id: '/_layout/settings/import-export'
      path: '/settings/import-export'
      fullPath: '/settings/import-export'
      preLoaderRoute: typeof LayoutSettingsImportExportImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/settings/keywords': {
      id: '/_layout/settings/keywords'
      path: '/settings/keywords'
      fullPath: '/settings/keywords'
      preLoaderRoute: typeof LayoutSettingsKeywordsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/settings/nas-settings': {
      id: '/_layout/settings/nas-settings'
      path: '/settings/nas-settings'
      fullPath: '/settings/nas-settings'
      preLoaderRoute: typeof LayoutSettingsNasSettingsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/settings/role-settings': {
      id: '/_layout/settings/role-settings'
      path: '/settings/role-settings'
      fullPath: '/settings/role-settings'
      preLoaderRoute: typeof LayoutSettingsRoleSettingsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/settings/sensitive-word-settings': {
      id: '/_layout/settings/sensitive-word-settings'
      path: '/settings/sensitive-word-settings'
      fullPath: '/settings/sensitive-word-settings'
      preLoaderRoute: typeof LayoutSettingsSensitiveWordSettingsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/settings/user-settings': {
      id: '/_layout/settings/user-settings'
      path: '/settings/user-settings'
      fullPath: '/settings/user-settings'
      preLoaderRoute: typeof LayoutSettingsUserSettingsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/settings/water-mark-settings': {
      id: '/_layout/settings/water-mark-settings'
      path: '/settings/water-mark-settings'
      fullPath: '/settings/water-mark-settings'
      preLoaderRoute: typeof LayoutSettingsWaterMarkSettingsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/settings/whitelist-settings': {
      id: '/_layout/settings/whitelist-settings'
      path: '/settings/whitelist-settings'
      fullPath: '/settings/whitelist-settings'
      preLoaderRoute: typeof LayoutSettingsWhitelistSettingsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/trash/$fileId': {
      id: '/_layout/trash/$fileId'
      path: '/trash/$fileId'
      fullPath: '/trash/$fileId'
      preLoaderRoute: typeof LayoutTrashFileIdImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/files/': {
      id: '/_layout/files/'
      path: '/files'
      fullPath: '/files'
      preLoaderRoute: typeof LayoutFilesIndexImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/folders/': {
      id: '/_layout/folders/'
      path: '/folders'
      fullPath: '/folders'
      preLoaderRoute: typeof LayoutFoldersIndexImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/settings/': {
      id: '/_layout/settings/'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof LayoutSettingsIndexImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/trash/': {
      id: '/_layout/trash/'
      path: '/trash'
      fullPath: '/trash'
      preLoaderRoute: typeof LayoutTrashIndexImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/nas/$nasId/search': {
      id: '/_layout/nas/$nasId/search'
      path: '/nas/$nasId/search'
      fullPath: '/nas/$nasId/search'
      preLoaderRoute: typeof LayoutNasNasIdSearchImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/settings/departments/$departmentId': {
      id: '/_layout/settings/departments/$departmentId'
      path: '/settings/departments/$departmentId'
      fullPath: '/settings/departments/$departmentId'
      preLoaderRoute: typeof LayoutSettingsDepartmentsDepartmentIdImport
      parentRoute: typeof LayoutImport
    }
    '/nas/$nasId/file/$nasFileId': {
      id: '/nas/$nasId/file/$nasFileId'
      path: '/nas/$nasId/file/$nasFileId'
      fullPath: '/nas/$nasId/file/$nasFileId'
      preLoaderRoute: typeof NasNasIdFileNasFileIdImport
      parentRoute: typeof rootRoute
    }
    '/_layout/nas/$nasId/': {
      id: '/_layout/nas/$nasId/'
      path: '/nas/$nasId'
      fullPath: '/nas/$nasId'
      preLoaderRoute: typeof LayoutNasNasIdIndexImport
      parentRoute: typeof LayoutImport
    }
  }
}

// Create and export the route tree

interface LayoutRouteChildren {
  LayoutAuditReportsRoute: typeof LayoutAuditReportsRoute
  LayoutFileHistoryRecordsRoute: typeof LayoutFileHistoryRecordsRoute
  LayoutFileMetricsRecordsRoute: typeof LayoutFileMetricsRecordsRoute
  LayoutProfileAuthRoute: typeof LayoutProfileAuthRoute
  LayoutSearchRoute: typeof LayoutSearchRoute
  LayoutIndexRoute: typeof LayoutIndexRoute
  LayoutConfidentialFilesDepartmentIdRoute: typeof LayoutConfidentialFilesDepartmentIdRoute
  LayoutFoldersFolderIdRoute: typeof LayoutFoldersFolderIdRoute
  LayoutProfile2faSettingsRoute: typeof LayoutProfile2faSettingsRoute
  LayoutProfileBasicSettingsRoute: typeof LayoutProfileBasicSettingsRoute
  LayoutProfileChangeCompanyRoute: typeof LayoutProfileChangeCompanyRoute
  LayoutProfileIntegrationRoute: typeof LayoutProfileIntegrationRoute
  LayoutSettingsAdminSettingsRoute: typeof LayoutSettingsAdminSettingsRoute
  LayoutSettingsDepartmentSettingsRoute: typeof LayoutSettingsDepartmentSettingsRoute
  LayoutSettingsImportExportRoute: typeof LayoutSettingsImportExportRoute
  LayoutSettingsKeywordsRoute: typeof LayoutSettingsKeywordsRoute
  LayoutSettingsNasSettingsRoute: typeof LayoutSettingsNasSettingsRoute
  LayoutSettingsRoleSettingsRoute: typeof LayoutSettingsRoleSettingsRoute
  LayoutSettingsSensitiveWordSettingsRoute: typeof LayoutSettingsSensitiveWordSettingsRoute
  LayoutSettingsUserSettingsRoute: typeof LayoutSettingsUserSettingsRoute
  LayoutSettingsWaterMarkSettingsRoute: typeof LayoutSettingsWaterMarkSettingsRoute
  LayoutSettingsWhitelistSettingsRoute: typeof LayoutSettingsWhitelistSettingsRoute
  LayoutTrashFileIdRoute: typeof LayoutTrashFileIdRoute
  LayoutFilesIndexRoute: typeof LayoutFilesIndexRoute
  LayoutFoldersIndexRoute: typeof LayoutFoldersIndexRoute
  LayoutSettingsIndexRoute: typeof LayoutSettingsIndexRoute
  LayoutTrashIndexRoute: typeof LayoutTrashIndexRoute
  LayoutNasNasIdSearchRoute: typeof LayoutNasNasIdSearchRoute
  LayoutSettingsDepartmentsDepartmentIdRoute: typeof LayoutSettingsDepartmentsDepartmentIdRoute
  LayoutNasNasIdIndexRoute: typeof LayoutNasNasIdIndexRoute
}

const LayoutRouteChildren: LayoutRouteChildren = {
  LayoutAuditReportsRoute: LayoutAuditReportsRoute,
  LayoutFileHistoryRecordsRoute: LayoutFileHistoryRecordsRoute,
  LayoutFileMetricsRecordsRoute: LayoutFileMetricsRecordsRoute,
  LayoutProfileAuthRoute: LayoutProfileAuthRoute,
  LayoutSearchRoute: LayoutSearchRoute,
  LayoutIndexRoute: LayoutIndexRoute,
  LayoutConfidentialFilesDepartmentIdRoute:
    LayoutConfidentialFilesDepartmentIdRoute,
  LayoutFoldersFolderIdRoute: LayoutFoldersFolderIdRoute,
  LayoutProfile2faSettingsRoute: LayoutProfile2faSettingsRoute,
  LayoutProfileBasicSettingsRoute: LayoutProfileBasicSettingsRoute,
  LayoutProfileChangeCompanyRoute: LayoutProfileChangeCompanyRoute,
  LayoutProfileIntegrationRoute: LayoutProfileIntegrationRoute,
  LayoutSettingsAdminSettingsRoute: LayoutSettingsAdminSettingsRoute,
  LayoutSettingsDepartmentSettingsRoute: LayoutSettingsDepartmentSettingsRoute,
  LayoutSettingsImportExportRoute: LayoutSettingsImportExportRoute,
  LayoutSettingsKeywordsRoute: LayoutSettingsKeywordsRoute,
  LayoutSettingsNasSettingsRoute: LayoutSettingsNasSettingsRoute,
  LayoutSettingsRoleSettingsRoute: LayoutSettingsRoleSettingsRoute,
  LayoutSettingsSensitiveWordSettingsRoute:
    LayoutSettingsSensitiveWordSettingsRoute,
  LayoutSettingsUserSettingsRoute: LayoutSettingsUserSettingsRoute,
  LayoutSettingsWaterMarkSettingsRoute: LayoutSettingsWaterMarkSettingsRoute,
  LayoutSettingsWhitelistSettingsRoute: LayoutSettingsWhitelistSettingsRoute,
  LayoutTrashFileIdRoute: LayoutTrashFileIdRoute,
  LayoutFilesIndexRoute: LayoutFilesIndexRoute,
  LayoutFoldersIndexRoute: LayoutFoldersIndexRoute,
  LayoutSettingsIndexRoute: LayoutSettingsIndexRoute,
  LayoutTrashIndexRoute: LayoutTrashIndexRoute,
  LayoutNasNasIdSearchRoute: LayoutNasNasIdSearchRoute,
  LayoutSettingsDepartmentsDepartmentIdRoute:
    LayoutSettingsDepartmentsDepartmentIdRoute,
  LayoutNasNasIdIndexRoute: LayoutNasNasIdIndexRoute,
}

const LayoutRouteWithChildren =
  LayoutRoute._addFileChildren(LayoutRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof LayoutRouteWithChildren
  '/email-verification': typeof EmailVerificationRoute
  '/forgot-password': typeof ForgotPasswordRoute
  '/login': typeof LoginRoute
  '/onboarding': typeof OnboardingRoute
  '/otp-verification': typeof OtpVerificationRoute
  '/reset-password': typeof ResetPasswordRoute
  '/select-company': typeof SelectCompanyRoute
  '/audit-reports': typeof LayoutAuditReportsRoute
  '/file-history-records': typeof LayoutFileHistoryRecordsRoute
  '/file-metrics-records': typeof LayoutFileMetricsRecordsRoute
  '/profile-auth': typeof LayoutProfileAuthRoute
  '/search': typeof LayoutSearchRoute
  '/files/$fileId': typeof FilesFileIdRoute
  '/': typeof LayoutIndexRoute
  '/confidential-files/$departmentId': typeof LayoutConfidentialFilesDepartmentIdRoute
  '/folders/$folderId': typeof LayoutFoldersFolderIdRoute
  '/profile/2fa-settings': typeof LayoutProfile2faSettingsRoute
  '/profile/basic-settings': typeof LayoutProfileBasicSettingsRoute
  '/profile/change-company': typeof LayoutProfileChangeCompanyRoute
  '/profile/integration': typeof LayoutProfileIntegrationRoute
  '/settings/admin-settings': typeof LayoutSettingsAdminSettingsRoute
  '/settings/department-settings': typeof LayoutSettingsDepartmentSettingsRoute
  '/settings/import-export': typeof LayoutSettingsImportExportRoute
  '/settings/keywords': typeof LayoutSettingsKeywordsRoute
  '/settings/nas-settings': typeof LayoutSettingsNasSettingsRoute
  '/settings/role-settings': typeof LayoutSettingsRoleSettingsRoute
  '/settings/sensitive-word-settings': typeof LayoutSettingsSensitiveWordSettingsRoute
  '/settings/user-settings': typeof LayoutSettingsUserSettingsRoute
  '/settings/water-mark-settings': typeof LayoutSettingsWaterMarkSettingsRoute
  '/settings/whitelist-settings': typeof LayoutSettingsWhitelistSettingsRoute
  '/trash/$fileId': typeof LayoutTrashFileIdRoute
  '/files': typeof LayoutFilesIndexRoute
  '/folders': typeof LayoutFoldersIndexRoute
  '/settings': typeof LayoutSettingsIndexRoute
  '/trash': typeof LayoutTrashIndexRoute
  '/nas/$nasId/search': typeof LayoutNasNasIdSearchRoute
  '/settings/departments/$departmentId': typeof LayoutSettingsDepartmentsDepartmentIdRoute
  '/nas/$nasId/file/$nasFileId': typeof NasNasIdFileNasFileIdRoute
  '/nas/$nasId': typeof LayoutNasNasIdIndexRoute
}

export interface FileRoutesByTo {
  '/email-verification': typeof EmailVerificationRoute
  '/forgot-password': typeof ForgotPasswordRoute
  '/login': typeof LoginRoute
  '/onboarding': typeof OnboardingRoute
  '/otp-verification': typeof OtpVerificationRoute
  '/reset-password': typeof ResetPasswordRoute
  '/select-company': typeof SelectCompanyRoute
  '/audit-reports': typeof LayoutAuditReportsRoute
  '/file-history-records': typeof LayoutFileHistoryRecordsRoute
  '/file-metrics-records': typeof LayoutFileMetricsRecordsRoute
  '/profile-auth': typeof LayoutProfileAuthRoute
  '/search': typeof LayoutSearchRoute
  '/files/$fileId': typeof FilesFileIdRoute
  '/': typeof LayoutIndexRoute
  '/confidential-files/$departmentId': typeof LayoutConfidentialFilesDepartmentIdRoute
  '/folders/$folderId': typeof LayoutFoldersFolderIdRoute
  '/profile/2fa-settings': typeof LayoutProfile2faSettingsRoute
  '/profile/basic-settings': typeof LayoutProfileBasicSettingsRoute
  '/profile/change-company': typeof LayoutProfileChangeCompanyRoute
  '/profile/integration': typeof LayoutProfileIntegrationRoute
  '/settings/admin-settings': typeof LayoutSettingsAdminSettingsRoute
  '/settings/department-settings': typeof LayoutSettingsDepartmentSettingsRoute
  '/settings/import-export': typeof LayoutSettingsImportExportRoute
  '/settings/keywords': typeof LayoutSettingsKeywordsRoute
  '/settings/nas-settings': typeof LayoutSettingsNasSettingsRoute
  '/settings/role-settings': typeof LayoutSettingsRoleSettingsRoute
  '/settings/sensitive-word-settings': typeof LayoutSettingsSensitiveWordSettingsRoute
  '/settings/user-settings': typeof LayoutSettingsUserSettingsRoute
  '/settings/water-mark-settings': typeof LayoutSettingsWaterMarkSettingsRoute
  '/settings/whitelist-settings': typeof LayoutSettingsWhitelistSettingsRoute
  '/trash/$fileId': typeof LayoutTrashFileIdRoute
  '/files': typeof LayoutFilesIndexRoute
  '/folders': typeof LayoutFoldersIndexRoute
  '/settings': typeof LayoutSettingsIndexRoute
  '/trash': typeof LayoutTrashIndexRoute
  '/nas/$nasId/search': typeof LayoutNasNasIdSearchRoute
  '/settings/departments/$departmentId': typeof LayoutSettingsDepartmentsDepartmentIdRoute
  '/nas/$nasId/file/$nasFileId': typeof NasNasIdFileNasFileIdRoute
  '/nas/$nasId': typeof LayoutNasNasIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_layout': typeof LayoutRouteWithChildren
  '/email-verification': typeof EmailVerificationRoute
  '/forgot-password': typeof ForgotPasswordRoute
  '/login': typeof LoginRoute
  '/onboarding': typeof OnboardingRoute
  '/otp-verification': typeof OtpVerificationRoute
  '/reset-password': typeof ResetPasswordRoute
  '/select-company': typeof SelectCompanyRoute
  '/_layout/audit-reports': typeof LayoutAuditReportsRoute
  '/_layout/file-history-records': typeof LayoutFileHistoryRecordsRoute
  '/_layout/file-metrics-records': typeof LayoutFileMetricsRecordsRoute
  '/_layout/profile-auth': typeof LayoutProfileAuthRoute
  '/_layout/search': typeof LayoutSearchRoute
  '/files/$fileId': typeof FilesFileIdRoute
  '/_layout/': typeof LayoutIndexRoute
  '/_layout/confidential-files/$departmentId': typeof LayoutConfidentialFilesDepartmentIdRoute
  '/_layout/folders/$folderId': typeof LayoutFoldersFolderIdRoute
  '/_layout/profile/2fa-settings': typeof LayoutProfile2faSettingsRoute
  '/_layout/profile/basic-settings': typeof LayoutProfileBasicSettingsRoute
  '/_layout/profile/change-company': typeof LayoutProfileChangeCompanyRoute
  '/_layout/profile/integration': typeof LayoutProfileIntegrationRoute
  '/_layout/settings/admin-settings': typeof LayoutSettingsAdminSettingsRoute
  '/_layout/settings/department-settings': typeof LayoutSettingsDepartmentSettingsRoute
  '/_layout/settings/import-export': typeof LayoutSettingsImportExportRoute
  '/_layout/settings/keywords': typeof LayoutSettingsKeywordsRoute
  '/_layout/settings/nas-settings': typeof LayoutSettingsNasSettingsRoute
  '/_layout/settings/role-settings': typeof LayoutSettingsRoleSettingsRoute
  '/_layout/settings/sensitive-word-settings': typeof LayoutSettingsSensitiveWordSettingsRoute
  '/_layout/settings/user-settings': typeof LayoutSettingsUserSettingsRoute
  '/_layout/settings/water-mark-settings': typeof LayoutSettingsWaterMarkSettingsRoute
  '/_layout/settings/whitelist-settings': typeof LayoutSettingsWhitelistSettingsRoute
  '/_layout/trash/$fileId': typeof LayoutTrashFileIdRoute
  '/_layout/files/': typeof LayoutFilesIndexRoute
  '/_layout/folders/': typeof LayoutFoldersIndexRoute
  '/_layout/settings/': typeof LayoutSettingsIndexRoute
  '/_layout/trash/': typeof LayoutTrashIndexRoute
  '/_layout/nas/$nasId/search': typeof LayoutNasNasIdSearchRoute
  '/_layout/settings/departments/$departmentId': typeof LayoutSettingsDepartmentsDepartmentIdRoute
  '/nas/$nasId/file/$nasFileId': typeof NasNasIdFileNasFileIdRoute
  '/_layout/nas/$nasId/': typeof LayoutNasNasIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/email-verification'
    | '/forgot-password'
    | '/login'
    | '/onboarding'
    | '/otp-verification'
    | '/reset-password'
    | '/select-company'
    | '/audit-reports'
    | '/file-history-records'
    | '/file-metrics-records'
    | '/profile-auth'
    | '/search'
    | '/files/$fileId'
    | '/'
    | '/confidential-files/$departmentId'
    | '/folders/$folderId'
    | '/profile/2fa-settings'
    | '/profile/basic-settings'
    | '/profile/change-company'
    | '/profile/integration'
    | '/settings/admin-settings'
    | '/settings/department-settings'
    | '/settings/import-export'
    | '/settings/keywords'
    | '/settings/nas-settings'
    | '/settings/role-settings'
    | '/settings/sensitive-word-settings'
    | '/settings/user-settings'
    | '/settings/water-mark-settings'
    | '/settings/whitelist-settings'
    | '/trash/$fileId'
    | '/files'
    | '/folders'
    | '/settings'
    | '/trash'
    | '/nas/$nasId/search'
    | '/settings/departments/$departmentId'
    | '/nas/$nasId/file/$nasFileId'
    | '/nas/$nasId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/email-verification'
    | '/forgot-password'
    | '/login'
    | '/onboarding'
    | '/otp-verification'
    | '/reset-password'
    | '/select-company'
    | '/audit-reports'
    | '/file-history-records'
    | '/file-metrics-records'
    | '/profile-auth'
    | '/search'
    | '/files/$fileId'
    | '/'
    | '/confidential-files/$departmentId'
    | '/folders/$folderId'
    | '/profile/2fa-settings'
    | '/profile/basic-settings'
    | '/profile/change-company'
    | '/profile/integration'
    | '/settings/admin-settings'
    | '/settings/department-settings'
    | '/settings/import-export'
    | '/settings/keywords'
    | '/settings/nas-settings'
    | '/settings/role-settings'
    | '/settings/sensitive-word-settings'
    | '/settings/user-settings'
    | '/settings/water-mark-settings'
    | '/settings/whitelist-settings'
    | '/trash/$fileId'
    | '/files'
    | '/folders'
    | '/settings'
    | '/trash'
    | '/nas/$nasId/search'
    | '/settings/departments/$departmentId'
    | '/nas/$nasId/file/$nasFileId'
    | '/nas/$nasId'
  id:
    | '__root__'
    | '/_layout'
    | '/email-verification'
    | '/forgot-password'
    | '/login'
    | '/onboarding'
    | '/otp-verification'
    | '/reset-password'
    | '/select-company'
    | '/_layout/audit-reports'
    | '/_layout/file-history-records'
    | '/_layout/file-metrics-records'
    | '/_layout/profile-auth'
    | '/_layout/search'
    | '/files/$fileId'
    | '/_layout/'
    | '/_layout/confidential-files/$departmentId'
    | '/_layout/folders/$folderId'
    | '/_layout/profile/2fa-settings'
    | '/_layout/profile/basic-settings'
    | '/_layout/profile/change-company'
    | '/_layout/profile/integration'
    | '/_layout/settings/admin-settings'
    | '/_layout/settings/department-settings'
    | '/_layout/settings/import-export'
    | '/_layout/settings/keywords'
    | '/_layout/settings/nas-settings'
    | '/_layout/settings/role-settings'
    | '/_layout/settings/sensitive-word-settings'
    | '/_layout/settings/user-settings'
    | '/_layout/settings/water-mark-settings'
    | '/_layout/settings/whitelist-settings'
    | '/_layout/trash/$fileId'
    | '/_layout/files/'
    | '/_layout/folders/'
    | '/_layout/settings/'
    | '/_layout/trash/'
    | '/_layout/nas/$nasId/search'
    | '/_layout/settings/departments/$departmentId'
    | '/nas/$nasId/file/$nasFileId'
    | '/_layout/nas/$nasId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  LayoutRoute: typeof LayoutRouteWithChildren
  EmailVerificationRoute: typeof EmailVerificationRoute
  ForgotPasswordRoute: typeof ForgotPasswordRoute
  LoginRoute: typeof LoginRoute
  OnboardingRoute: typeof OnboardingRoute
  OtpVerificationRoute: typeof OtpVerificationRoute
  ResetPasswordRoute: typeof ResetPasswordRoute
  SelectCompanyRoute: typeof SelectCompanyRoute
  FilesFileIdRoute: typeof FilesFileIdRoute
  NasNasIdFileNasFileIdRoute: typeof NasNasIdFileNasFileIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  LayoutRoute: LayoutRouteWithChildren,
  EmailVerificationRoute: EmailVerificationRoute,
  ForgotPasswordRoute: ForgotPasswordRoute,
  LoginRoute: LoginRoute,
  OnboardingRoute: OnboardingRoute,
  OtpVerificationRoute: OtpVerificationRoute,
  ResetPasswordRoute: ResetPasswordRoute,
  SelectCompanyRoute: SelectCompanyRoute,
  FilesFileIdRoute: FilesFileIdRoute,
  NasNasIdFileNasFileIdRoute: NasNasIdFileNasFileIdRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_layout",
        "/email-verification",
        "/forgot-password",
        "/login",
        "/onboarding",
        "/otp-verification",
        "/reset-password",
        "/select-company",
        "/files/$fileId",
        "/nas/$nasId/file/$nasFileId"
      ]
    },
    "/_layout": {
      "filePath": "_layout.tsx",
      "children": [
        "/_layout/audit-reports",
        "/_layout/file-history-records",
        "/_layout/file-metrics-records",
        "/_layout/profile-auth",
        "/_layout/search",
        "/_layout/",
        "/_layout/confidential-files/$departmentId",
        "/_layout/folders/$folderId",
        "/_layout/profile/2fa-settings",
        "/_layout/profile/basic-settings",
        "/_layout/profile/change-company",
        "/_layout/profile/integration",
        "/_layout/settings/admin-settings",
        "/_layout/settings/department-settings",
        "/_layout/settings/import-export",
        "/_layout/settings/keywords",
        "/_layout/settings/nas-settings",
        "/_layout/settings/role-settings",
        "/_layout/settings/sensitive-word-settings",
        "/_layout/settings/user-settings",
        "/_layout/settings/water-mark-settings",
        "/_layout/settings/whitelist-settings",
        "/_layout/trash/$fileId",
        "/_layout/files/",
        "/_layout/folders/",
        "/_layout/settings/",
        "/_layout/trash/",
        "/_layout/nas/$nasId/search",
        "/_layout/settings/departments/$departmentId",
        "/_layout/nas/$nasId/"
      ]
    },
    "/email-verification": {
      "filePath": "email-verification.tsx"
    },
    "/forgot-password": {
      "filePath": "forgot-password.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/onboarding": {
      "filePath": "onboarding.tsx"
    },
    "/otp-verification": {
      "filePath": "otp-verification.tsx"
    },
    "/reset-password": {
      "filePath": "reset-password.tsx"
    },
    "/select-company": {
      "filePath": "select-company.tsx"
    },
    "/_layout/audit-reports": {
      "filePath": "_layout/audit-reports.tsx",
      "parent": "/_layout"
    },
    "/_layout/file-history-records": {
      "filePath": "_layout/file-history-records.tsx",
      "parent": "/_layout"
    },
    "/_layout/file-metrics-records": {
      "filePath": "_layout/file-metrics-records.tsx",
      "parent": "/_layout"
    },
    "/_layout/profile-auth": {
      "filePath": "_layout/profile-auth.tsx",
      "parent": "/_layout"
    },
    "/_layout/search": {
      "filePath": "_layout/search.tsx",
      "parent": "/_layout"
    },
    "/files/$fileId": {
      "filePath": "files/$fileId.tsx"
    },
    "/_layout/": {
      "filePath": "_layout/index.tsx",
      "parent": "/_layout"
    },
    "/_layout/confidential-files/$departmentId": {
      "filePath": "_layout/confidential-files/$departmentId.tsx",
      "parent": "/_layout"
    },
    "/_layout/folders/$folderId": {
      "filePath": "_layout/folders/$folderId.tsx",
      "parent": "/_layout"
    },
    "/_layout/profile/2fa-settings": {
      "filePath": "_layout/profile/2fa-settings.tsx",
      "parent": "/_layout"
    },
    "/_layout/profile/basic-settings": {
      "filePath": "_layout/profile/basic-settings.tsx",
      "parent": "/_layout"
    },
    "/_layout/profile/change-company": {
      "filePath": "_layout/profile/change-company.tsx",
      "parent": "/_layout"
    },
    "/_layout/profile/integration": {
      "filePath": "_layout/profile/integration.tsx",
      "parent": "/_layout"
    },
    "/_layout/settings/admin-settings": {
      "filePath": "_layout/settings/admin-settings.tsx",
      "parent": "/_layout"
    },
    "/_layout/settings/department-settings": {
      "filePath": "_layout/settings/department-settings.tsx",
      "parent": "/_layout"
    },
    "/_layout/settings/import-export": {
      "filePath": "_layout/settings/import-export.tsx",
      "parent": "/_layout"
    },
    "/_layout/settings/keywords": {
      "filePath": "_layout/settings/keywords.tsx",
      "parent": "/_layout"
    },
    "/_layout/settings/nas-settings": {
      "filePath": "_layout/settings/nas-settings.tsx",
      "parent": "/_layout"
    },
    "/_layout/settings/role-settings": {
      "filePath": "_layout/settings/role-settings.tsx",
      "parent": "/_layout"
    },
    "/_layout/settings/sensitive-word-settings": {
      "filePath": "_layout/settings/sensitive-word-settings.tsx",
      "parent": "/_layout"
    },
    "/_layout/settings/user-settings": {
      "filePath": "_layout/settings/user-settings.tsx",
      "parent": "/_layout"
    },
    "/_layout/settings/water-mark-settings": {
      "filePath": "_layout/settings/water-mark-settings.tsx",
      "parent": "/_layout"
    },
    "/_layout/settings/whitelist-settings": {
      "filePath": "_layout/settings/whitelist-settings.tsx",
      "parent": "/_layout"
    },
    "/_layout/trash/$fileId": {
      "filePath": "_layout/trash/$fileId.tsx",
      "parent": "/_layout"
    },
    "/_layout/files/": {
      "filePath": "_layout/files/index.tsx",
      "parent": "/_layout"
    },
    "/_layout/folders/": {
      "filePath": "_layout/folders/index.tsx",
      "parent": "/_layout"
    },
    "/_layout/settings/": {
      "filePath": "_layout/settings/index.tsx",
      "parent": "/_layout"
    },
    "/_layout/trash/": {
      "filePath": "_layout/trash/index.tsx",
      "parent": "/_layout"
    },
    "/_layout/nas/$nasId/search": {
      "filePath": "_layout/nas/$nasId/search.tsx",
      "parent": "/_layout"
    },
    "/_layout/settings/departments/$departmentId": {
      "filePath": "_layout/settings/departments/$departmentId.tsx",
      "parent": "/_layout"
    },
    "/nas/$nasId/file/$nasFileId": {
      "filePath": "nas/$nasId/file/$nasFileId.tsx"
    },
    "/_layout/nas/$nasId/": {
      "filePath": "_layout/nas/$nasId/index.tsx",
      "parent": "/_layout"
    }
  }
}
ROUTE_MANIFEST_END */

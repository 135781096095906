import { Edit, LoaderCircle } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { NasStatusDocument } from '@@graphql';

type NasCardProps = {
  id: string;
  name: string;
  host: string;
  isLoading: boolean;
  setCurrentEditNasId: (id: string) => void;
  setOpenEditDialog: (open: boolean) => void;
};

type StatusType = {
  text: string;
  bgColor: string;
  textColor: string;
}

const NasCard = ({
  id,
  name,
  host,
  isLoading,
  setCurrentEditNasId,
  setOpenEditDialog,
}: NasCardProps) => {
  const defaultStatus = { text: '連線中', bgColor: 'bg-yellow-100', textColor: 'text-yellow-500' }
  const successStatus = { text: '連線成功', bgColor: 'bg-green-100', textColor: 'text-green-500' }
  const failStatus = { text: '連線失敗', bgColor: 'bg-red-100', textColor: 'text-red-500' }
  const [status, setStatus] = useState<StatusType>(defaultStatus);

  useQuery(NasStatusDocument, {
    variables: { nasId: id },
    onCompleted: data => {
      if (data.nasStatus) {
        setStatus(successStatus);
      } else {
        setStatus(failStatus);
      }
    },
  })

  return (
    <Card className="col-span-1">
      <CardHeader className="flex flex-row items-center justify-between p-4 space-y-0 pb-2">
        <Badge
          variant="secondary"
          className={`${status.bgColor} hover:${status.bgColor} px-2 py-1`}
        >
          <span className={`${status.textColor} mr-1`}>●</span>
          <p className="text-xs font-normal">{status.text}</p>
        </Badge>
        <div
          className="flex items-center justify-center text-xs cursor-pointer"
          onClick={() => {
            setCurrentEditNasId(id);
            setOpenEditDialog(true);
          }}
        >
          {isLoading ? (
            <LoaderCircle className="w-4 h-4 mr-1 animate-spin" />
          ) : (
            <Edit className="w-4 h-4 mr-1" />
          )}
          {isLoading ? '載入中...' : '編輯'}
        </div>
      </CardHeader>
      <CardContent className="p-4 pt-1">
        <CardTitle className="text-sm font-medium">{name}</CardTitle>
        <div className="text-xs">{host}</div>
      </CardContent>
    </Card>
  );
};

export default NasCard;

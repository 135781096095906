import { NasFileModel } from '@/types';
import { formatFileSize, transFormDate, transformMimeType } from '@/utils/file.ts';
import { NasFileType } from '@@graphql';

export const nasFileModelToRealFileModel = (nasFileRawData: NasFileModel[]) => {
  return nasFileRawData.map(
    ({ basename, updatedAt, createdAt, id, mime, size, type }) => {
      return {
        id,
        updatedAt: transFormDate(updatedAt),
        createdAt: transFormDate(createdAt),
        isDir: type === NasFileType.Directory,
        name: basename,
        type: transformMimeType(mime),
        size: formatFileSize(+size),
        departmentName: '',
        isPrivate: false,
        pinnedAt: undefined,
      };
    },
  );
};

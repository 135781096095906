import debounce from 'debounce';
import React, { useCallback, useEffect, useRef } from 'react';

interface InfiniteScrollProps {
  loadMore: (isInitial: boolean) => void;
  hasMore: boolean;
  isLoading: boolean;
  children: React.ReactNode;
}

const InfiniteScrollWrapper: React.FC<InfiniteScrollProps> = ({
  loadMore,
  hasMore,
  isLoading,
  children,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(
    debounce((containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement && !isLoading && hasMore) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        const isNearBottom = scrollHeight - scrollTop - clientHeight < 50;
        if (isNearBottom) {
          loadMore(false);
        }
      }
    }, 300),
    [hasMore, isLoading, loadMore],
  );

  useEffect(() => {
    const containerRefElement = scrollContainerRef.current;
    if (containerRefElement) {
      handleScroll(containerRefElement);
    }
  }, [handleScroll]);

  return (
    <div
      onScroll={e => handleScroll(e.currentTarget)}
      ref={scrollContainerRef}
      className="h-[calc(100vh-70px)] overflow-y-auto"
    >
      {children}
      {isLoading && <div>Loading more...</div>}
    </div>
  );
};

export default InfiniteScrollWrapper;

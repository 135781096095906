import { useState } from 'react';
import SettingLayout from '@/components/layout/setting-layout';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import CreateNasDialog from './create-nas-dialog';
import NasCard from './nas-card';
import { NasModel } from '@@graphql';
import { useNasStore } from '@/store/nas';
import Loading from '@/components/loading';
import { useQuery } from '@apollo/client';
import { NasDocument } from '@@graphql';
import EditNasDialog from './edit-nas-dialog';
import EmptyData from '@/components/empty-data';

const NasSettings = () => {
  const nasList = useNasStore(state => state.nasList);
  const isNasListLoading = useNasStore(state => state.isNasListLoading);
  const [currentEditNasId, setCurrentEditNasId] = useState<string | null>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const { data: nasData, loading: isNasDataLoading } = useQuery(NasDocument, {
    variables: { id: currentEditNasId },
    skip: !openEditDialog || !currentEditNasId,
    fetchPolicy: 'network-only',
  });

  const defaultNas = nasData?.nas as NasModel;

  return (
    <SettingLayout>
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle>NAS 管理</CardTitle>
            <CreateNasDialog />
          </div>
          <CardDescription>
            此頁面提供新增、修改、刪除 NAS 連線的功能。完成連線設定後，NAS
            設備將會自動顯示於左側選單中，方便用戶快速存取與管理。
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-2 h-[430px]">
          {isNasListLoading ? (
            <div className="flex justify-center items-center h-[inherit]">
              <Loading />
            </div>
          ) : nasList.length === 0 ? (
            <EmptyData
              title="目前沒有任何 NAS 連線，請先新增 NAS 連線"
              wrapperHeight="h-[340px]"
            />
          ) : (
            <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 mt-4">
              {nasList.map((nas: NasModel) => (
                <NasCard
                  key={nas.id}
                  id={nas.id}
                  name={nas.name}
                  host={nas.host}
                  isLoading={isNasDataLoading && currentEditNasId === nas.id}
                  setCurrentEditNasId={setCurrentEditNasId}
                  setOpenEditDialog={setOpenEditDialog}
                />
              ))}
            </div>
          )}
        </CardContent>
      </Card>
      {!isNasDataLoading && openEditDialog && currentEditNasId && (
        <EditNasDialog
          id={currentEditNasId}
          open={openEditDialog}
          setOpen={setOpenEditDialog}
          defaultNas={defaultNas}
        />
      )}
    </SettingLayout>
  );
};

export default NasSettings;

import { useCallback, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useMutation } from '@apollo/client';
import { CreateNasDocument, NasListDocument } from '@@graphql';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { UserRoundPlus } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { toast } from '@/components/ui/use-toast.ts';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import CheckNasConnectionButton from './check-nas-connection-button';
const FormSchema = z.object({
  name: z.string().min(1, { message: '名稱不能為空' }),
  host: z
    .string()
    .refine(value => value.startsWith('http://') || value.startsWith('https://'), {
      message: '主機網址必須以 http:// 或 https:// 開頭',
    }),
  username: z.string().min(1, { message: '帳號不能為空' }),
  password: z.string().min(1, { message: '密碼不能為空' }),
  port: z.coerce.number().min(1, { message: '埠號不能為空' }).default(443),
});

const CreateNasDialog = () => {
  const [open, setOpen] = useState(false);
  const { handleError } = useErrorHandler({});
  const [isCreating, setIsCreating] = useState(false);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: '',
      host: '',
      username: '',
      password: '',
      port: 443,
    },
    mode: 'onBlur',
  });

  const { control, reset, clearErrors, watch } = form;
  const { host, username, password, port } = watch();

  const [createNas] = useMutation(CreateNasDocument, {
    refetchQueries: [{ query: NasListDocument }],
    onCompleted: () => {
      setIsCreating(false);
      toast({
        title: '儲存成功',
      });
      setOpen(false);
      form.reset();
    },
  });

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        reset();
        clearErrors();
      }
      setOpen(open);
    },
    [clearErrors, reset],
  );

  const onSubmit = useCallback(
    async (data: z.infer<typeof FormSchema>) => {
      try {
        setIsCreating(true);
        await createNas({
          variables: data,
        });
      } catch (err) {
        handleError(err);
      }
    },
    [createNas, handleError],
  );

  const columns = [
    {
      label: '名稱',
      name: 'name',
      type: 'text',
    },
    {
      label: '主機網址',
      name: 'host',
      type: 'text',
    },
    {
      label: '帳號',
      name: 'username',
      type: 'text',
    },
    {
      label: '密碼',
      name: 'password',
      type: 'password',
    },
    {
      label: '埠號',
      name: 'port',
      type: 'number',
    },
  ];

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button className="gap-1 font-normal text-primary" variant="ghost">
          <UserRoundPlus size={20} strokeWidth={1.5} /> 新增 NAS
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[400px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogHeader>
              <DialogTitle>新增 NAS</DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 mt-4 py-4">
              {columns.map(({ label, name, type }) => (
                <FormField
                  key={name}
                  control={control}
                  name={name as keyof z.infer<typeof FormSchema>}
                  render={({ field }) => (
                    <FormItem>
                      <div className="inline-flex items-center w-full">
                        <FormLabel className="w-[110px]">{label}</FormLabel>
                        <FormControl>
                          <Input
                            className="w-[230px] h-[32px]"
                            type={type}
                            {...field}
                          />
                        </FormControl>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ))}
            </div>
            <DialogFooter className="flex justify-end">
              <div className="flex gap-2">
                <CheckNasConnectionButton
                  host={host}
                  username={username}
                  password={password}
                  port={port}
                />
                <Button type="submit" loading={isCreating}>
                  儲存
                </Button>
              </div>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateNasDialog;

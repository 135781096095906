import { imgExtensions } from '../constants/extension';
import { FileType } from '../constants/files.tsx';

import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { FileModel, RealFileModel } from '@/types';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const wordMime =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const pptMime =
  'application/vnd.openxmlformats-officedocument.presentationml.presentation';
const xlsMime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const formatFileSize = (bytes: number | null) => {
  if (!bytes) return '--';
  const KB = 1024;
  const MB = KB * 1024;
  const GB = MB * 1024;

  if (bytes < KB) {
    return bytes + ' bytes';
  } else if (bytes < MB) {
    return (bytes / KB).toFixed(1) + ' KB';
  } else if (bytes < GB) {
    return (bytes / MB).toFixed(1) + ' MB';
  } else {
    return (bytes / GB).toFixed(2) + ' GB';
  }
};

export const transformFileType = (ext: string | undefined) => {
  if (!ext) return undefined;
  if (imgExtensions.includes(ext)) return FileType.IMG;
  if (ext === '.pdf') return FileType.PDF;
  if (ext === '.docx') return FileType.DOCX;
  if (ext === '.xlsx') return FileType.XLSX;
  if (ext === '.pptx') return FileType.PPTX;
  return FileType.UNKNOWN;
};

export const transFormDate = (date: Date) => {
  const dayjsDate = dayjs(date);

  if (dayjsDate.isToday()) {
    return `今天 ${dayjsDate.format('HH:mm')}`;
  } else if (dayjsDate.isYesterday()) {
    return `昨天 ${dayjsDate.format('HH:mm')}`;
  } else {
    return dayjsDate.format('YYYY-MM-DD HH:mm');
  }
};

// 檔案下載
const decodeRFC5987 = (str: string): string => {
  return decodeURIComponent(
    str
      .replace(/%(7C|60|5E)/g, (_, hex) => String.fromCharCode(parseInt(hex, 16)))
      .replace(/%2A/g, '*')
      .replace(/%(2[1-9A-F]|3[0-9A-F]|40)/gi, (_, hex) =>
        String.fromCharCode(parseInt(hex, 16)),
      ),
  );
};

export const getFilenameFromContentDisposition = (
  contentDisposition: string | null,
): string => {
  if (!contentDisposition) return 'download';

  const filenameMatchUtf8 = contentDisposition.match(/filename=UTF-8''(.+)$/);
  if (filenameMatchUtf8 && filenameMatchUtf8[1]) {
    return decodeRFC5987(decodeURIComponent(filenameMatchUtf8[1]));
  }

  const filenameMatch = contentDisposition.match(/filename="(.+?)";/);
  return filenameMatch && filenameMatch[1] ? filenameMatch[1] : 'download';
};

export const downloadFile = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const downloadFileFromUrl = async (url: string, filename: string) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  if (!response.ok) {
    throw new Error('下載失敗');
  }
  const blob = await response.blob();
  downloadFile(blob, filename);
};

export const fileModelToRealFileModel = (
  fileRawData: FileModel[],
  departmentMap?: { [key: string]: string },
): RealFileModel[] => {
  return fileRawData.map(
    ({
      name,
      ext,
      isDir,
      id,
      size,
      updatedAt,
      createdAt,
      creatorName,
      isPrivate,
      departmentId,
      pinnedAt,
    }) => {
      let source = transFormDate(createdAt);
      if (!isDir) {
        source += `-${creatorName}`;
      }
      return {
        id,
        updatedAt: transFormDate(updatedAt),
        createdAt: transFormDate(createdAt),
        creationInfo: source,
        isDir,
        name,
        type: isDir ? undefined : transformFileType(ext),
        size: formatFileSize(size),
        isPrivate,
        departmentName: departmentMap && departmentMap[departmentId as string],
        pinnedAt,
      };
    },
  );
};

export const transformMimeType = (mime: string) => {
  if (!mime) return FileType.UNKNOWN;
  if (mime.includes('image/')) return FileType.IMG;
  if (mime.includes('application/pdf')) return FileType.PDF;
  if (mime.includes(wordMime)) return FileType.DOCX;
  if (mime.includes(xlsMime)) return FileType.XLSX;
  if (mime.includes(pptMime)) return FileType.PPTX;
  return FileType.UNKNOWN;
};

import { Fragment, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { createFileRoute, useRouter } from '@tanstack/react-router';
import { dateTimeFormat, stopPropagationClickHandlers } from '@/lib/utils';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import NasTableView from '@/components/layout/nas-table-view.tsx';
import { Row } from '@tanstack/react-table';
import {
  NasBreadcrumbsDocument,
  NasFileModel,
  NasFileType,
  NasModel,
  NasWithSyncLogDocument,
  TimeFieldEnum,
} from '@@graphql';
import DatePickerWithRange from '@/components/date-picker-with-range';
import { DateRange } from 'react-day-picker';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { SearchMime } from '@/constants';
import { goToNasFile, goToNasFolder } from '@/lib/router';
import { BreadcrumbModel } from '@/types';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import FileDownload from '@/components/ui/file-download';

export const Route = createFileRoute('/_layout/nas/$nasId/')({
  component: NasFileComponent,
});

interface SearchParams {
  nasFileId?: string;
}

function NasFileComponent() {
  const router = useRouter();
  const { nasId } = Route.useParams();
  const [nasQuery] = useLazyQuery(NasWithSyncLogDocument, {
    fetchPolicy: 'network-only',
  });
  const [getNasBreadcrumbsQuery] = useLazyQuery(NasBreadcrumbsDocument, {
    fetchPolicy: 'network-only',
  });

  const [rowSelection, setRowSelection] = useState<Row<NasFileModel> | null>(null);
  const [searchMime, setSearchMime] = useState<string>(SearchMime.ALL);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbModel[]>([]);
  const [nas, setNas] = useState<NasModel>();
  const [currentTimeField, setCurrentTimeField] = useState<TimeFieldEnum | undefined>(
    undefined,
  );
  const [createdDateRange, setCreatedDateRange] = useState<DateRange | undefined>(
    undefined,
  );
  const [updatedDateRange, setUpdatedDateRange] = useState<DateRange | undefined>(
    undefined,
  );

  const nasFileId = (Route.useSearch() as SearchParams)?.nasFileId;

  useEffect(() => {
    nasQuery({
      variables: { id: nasId },
      onCompleted: data => {
        setNas(data.nas);
      },
    });
  }, [nasId, nasQuery]);

  useEffect(() => {
    if (nasFileId) {
      getNasBreadcrumbsQuery({
        variables: {
          nasId,
          nasFileId,
        },
        onCompleted: ({ nasFile }) => {
          setBreadcrumbs(nasFile.breadcrumbs);
        },
      });
    } else {
      setBreadcrumbs([]);
    }
  }, [nasFileId, getNasBreadcrumbsQuery, nasId]);

  const toggleFileSelection = (row?: Row<NasFileModel>) => {
    if (row?.original.type === NasFileType.Directory) {
      return;
    }
    setRowSelection(row ?? null);
    row && row.toggleSelected();
  };

  const handleDoubleClick = (file: NasFileModel) => {
    file.type === NasFileType.Directory
      ? goToNasFolder({
          router,
          nasId: file.nasId,
          nasFileId: file.id,
        })
      : goToNasFile({
          router,
          nasId: file.nasId,
          nasFileId: file.id,
        });
  };

  return (
    <div className="px-8">
      <div className="sticky py-4 top-0 bg-background z-10">
        <Breadcrumb>
          <BreadcrumbList>
            {breadcrumbs.map((item, index) => {
              if (index === 0) return null;
              return (
                <Fragment key={item.id}>
                  <BreadcrumbItem key={item.id}>
                    <BreadcrumbLink
                      className="cursor-pointer"
                      onClick={() =>
                        router.navigate({
                          to: '/nas/$nasId',
                          params: {
                            nasId,
                          },
                          search: {
                            nasFileId: breadcrumbs.length > 2 ? item.id : null,
                          },
                        })
                      }
                    >
                      {item.name}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  {index !== 0 && breadcrumbs.length - 1 !== index && (
                    <BreadcrumbSeparator />
                  )}
                </Fragment>
              );
            })}
          </BreadcrumbList>
        </Breadcrumb>
        <div className="pt-6 flex justify-between items-center">
          <div className="flex-1 items-center  gap-2">
            <h1 className="text-lg md:text-2xl font-bold">NAS 檔案列表</h1>
            <h2 className="text-sm text-muted-foreground pt-2">
              最新同步時間：{dateTimeFormat(nas?.latestSyncLog?.updatedAt)}
            </h2>
          </div>
        </div>
        <div className="pt-6 flex justify-between items-center gap-2">
          <div className="flex gap-2">
            <DatePickerWithRange
              date={createdDateRange}
              setDate={setCreatedDateRange}
              onSelect={() => {
                setCurrentTimeField(TimeFieldEnum.CreatedAt);
                setUpdatedDateRange(undefined);
              }}
              placeholder="建立時間"
            />
            <DatePickerWithRange
              date={updatedDateRange}
              setDate={setUpdatedDateRange}
              onSelect={() => {
                setCurrentTimeField(TimeFieldEnum.UpdatedAt);
                setCreatedDateRange(undefined);
              }}
              placeholder="上次修改時間"
            />
            <Select onValueChange={setSearchMime} value={searchMime}>
              <SelectTrigger className="w-[110px] focus:ring-transparent bg-background hover:bg-accent hover:text-accent-foreground">
                <SelectValue placeholder="類型" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={SearchMime.ALL}>所有類型</SelectItem>
                <SelectItem value={SearchMime.PDF}>PDF</SelectItem>
                <SelectItem value={SearchMime.IMAGE}>圖片</SelectItem>
                <SelectItem value={SearchMime.OFFICE}>Office 相關</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div
            className="flex justify-center items-center gap-1"
            {...stopPropagationClickHandlers()}
          >
            {rowSelection && (
              <TooltipProvider key="下載">
                <Tooltip>
                  <TooltipTrigger>
                    <FileDownload
                      fileUrl={rowSelection.original.fileUrl as string}
                      onFinish={() => {
                        rowSelection.toggleSelected();
                      }}
                    />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>下載</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
        </div>
      </div>

      <NasTableView
        className={'max-h-[calc(100vh-280px)]'}
        nasId={nasId}
        nasFileId={nasFileId}
        currentTimeField={currentTimeField}
        createdDateRange={createdDateRange}
        updatedDateRange={updatedDateRange}
        searchMime={searchMime as SearchMime}
        handleClick={toggleFileSelection}
        handleDoubleClick={handleDoubleClick}
      />
    </div>
  );
}

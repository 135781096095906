import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn, getNasFileIcon } from '@/lib/utils';
import { NasFileModel, NasFilesDocument, TimeFieldEnum, NasFileType } from '@@graphql';
import SortColumn from '../sort-column';
import { Checkbox } from '@/components/ui/checkbox';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT, SearchMime, TAKE_PER_PAGE } from '@/constants';
import { formatFileSize } from '@/utils/file.ts';

import { ColumnDef, Row } from '@tanstack/react-table';

import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { useLazyQuery } from '@apollo/client';
import { DateRange } from 'react-day-picker';
import EmptyData from '../empty-data';
import debounce from 'debounce';
import { DataTable } from '../data-table';

interface NasViewerProps {
  className?: string;
  nasId?: string;
  nasFileId?: string;
  currentTimeField?: TimeFieldEnum;
  createdDateRange?: DateRange;
  updatedDateRange?: DateRange;
  searchMime?: SearchMime;
  handleClick: (row?: Row<NasFileModel>) => void;
  handleDoubleClick: (file: NasFileModel) => void;
}

const NasTableView = ({
  className,
  nasId,
  nasFileId,
  currentTimeField,
  createdDateRange,
  updatedDateRange,
  searchMime,
  handleClick,
  handleDoubleClick,
}: NasViewerProps) => {
  const [fetchNasFiles] = useLazyQuery(NasFilesDocument, {
    fetchPolicy: 'network-only',
  });
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const columns = useMemo<ColumnDef<NasFileModel>[]>(
    () => [
      {
        accessorKey: 'selector',
        header: ({ table }) => {
          return (
            <Checkbox
              className={cn('invisible p-0')}
              checked={
                table.getIsAllPageRowsSelected() ||
                (table.getIsSomePageRowsSelected() && 'indeterminate')
              }
              aria-label="Select all"
            />
          );
        },
        cell: ({ row }) => {
          return (
            <Checkbox
              className={cn('invisible', row.getIsSelected() && 'visible')}
              disabled={row.original.type === NasFileType.Directory}
              checked={row.getIsSelected()}
              onChange={row.getToggleSelectedHandler()}
              aria-label="Select row"
            />
          );
        },
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: 'basename',
        header: ({ column }) => <SortColumn name="名稱" column={column} />,
        cell: ({ row }) => {
          const file = row.original;
          return (
            <div
              className="flex flex-row flex-nowrap items-center gap-3"
              role="listbox"
              aria-label={file.basename}
            >
              {getNasFileIcon(file)}
              {file.basename}
            </div>
          );
        },
      },
      {
        accessorKey: 'size',
        header: ({ column }) => <SortColumn name="檔案大小" column={column} />,
        cell: ({ row }) => <div>{formatFileSize(row.getValue('size'))}</div>,
      },
      {
        accessorKey: 'createdAt',
        header: ({ column }) => (
          <SortColumn name="建立時間" column={column} className="px-6 justify-end" />
        ),
        cell: ({ row }) => (
          <div className="px-6 text-right">
            {dayjs(row.getValue('createdAt')).format(DATE_TIME_FORMAT)}
          </div>
        ),
      },
      {
        accessorKey: 'updatedAt',
        header: ({ column }) => (
          <SortColumn
            name="上次修改時間"
            column={column}
            className="px-6 justify-end"
          />
        ),
        cell: ({ row }) => (
          <div className="px-6 text-right">
            {dayjs(row.getValue('updatedAt')).format(DATE_TIME_FORMAT)}
          </div>
        ),
      },
    ],
    [],
  );
  const rowProps = useCallback(
    (row: Row<NasFileModel>) => {
      const file = row.original;
      return {
        className: 'cursor-pointer',
        onClick: () => handleClick(row),
        onDoubleClick: () => handleDoubleClick(file),
      };
    },
    [handleClick, handleDoubleClick],
  );
  const { data, fetchNextPage, isFetching, isLoading, refetch } = useInfiniteQuery({
    queryKey: ['nasFiles'],
    queryFn: async ({ pageParam = 0 }) => {
      const page = pageParam + 1;
      const fetchedData = await fetchNasFiles({
        variables: {
          nasId,
          nasFileId,
          timeField: currentTimeField,
          mime: searchMime,
          take: TAKE_PER_PAGE,
          page,
          timeRange: {
            startAt:
              currentTimeField === TimeFieldEnum.CreatedAt
                ? createdDateRange?.from
                : updatedDateRange?.from,
            endAt:
              currentTimeField === TimeFieldEnum.CreatedAt
                ? createdDateRange?.to
                : updatedDateRange?.to,
          },
        },
      });
      setHasNextPage(fetchedData.data.nasFiles.pageInfo.hasNextPage);
      return fetchedData;
    },
    initialPageParam: 0,
    getNextPageParam: (_lastGroup, groups) => groups.length,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [
    refetch,
    nasFileId,
    nasId,
    currentTimeField,
    createdDateRange,
    updatedDateRange,
    searchMime,
  ]);

  const flatData = useMemo(
    () => data?.pages?.flatMap(page => page.data.nasFiles.items) ?? [],
    [data],
  );
  const fetchMoreOnBottomReached = useCallback(
    debounce((containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement && !isFetching && hasNextPage) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;
        if (isNearBottom) {
          fetchNextPage();
        }
      }
    }, 100),
    [fetchNextPage, isFetching, hasNextPage],
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  if (isLoading) {
    return <>Loading...</>;
  }

  return flatData.length === 0 ? (
    <EmptyData />
  ) : (
    <>
      <div
        style={{
          overflow: 'auto', //our scrollable table container
          position: 'relative', //needed for sticky header
        }}
        className={cn('w-full rounded-md border bg-white', className)}
        onScroll={e => fetchMoreOnBottomReached(e.currentTarget)}
        ref={tableContainerRef}
      >
        <DataTable
          enableMultiRowSelection={false}
          className="min-h-[calc(100vh-280px)]"
          perPage={1000}
          data={flatData}
          columns={columns}
          rowProps={rowProps}
        />
      </div>
      {isFetching && <div>Fetching More...</div>}
    </>
  );
};

export default NasTableView;

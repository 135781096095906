import { Suspense, useEffect, useState, useMemo, lazy, ComponentType } from 'react';
import { useRouter } from '@tanstack/react-router';
import { History } from 'lucide-react';
import { cn } from '@/lib/utils';
import NasOcrViewer from '@/components/viewer/nas-ocr-viewer.tsx';
import { TooltipProvider } from '@/components/ui/tooltip';
// import FileInfoPanel from '@/pages/file-view/file-info-panel';
import { FileHeader } from '@/pages/file-view/file-header';
import { SidePanel } from '@/pages/file-view/side-panel';
import Loading from '@/components/loading';
import { useWindowSize } from '@react-hook/window-size';
import { FileViewerProps, PanelId, ViewerComponentType } from '@/pages/file-view';
import { useFileQuery } from '@/tanstack';
import { NasFileModel } from '@/types';

type NasFileViewProps = {
  data: NasFileModel;
};

const importComponent = (mime?: string) => {
  if (!mime) return lazy(() => import('@/components/viewer/unknown-viewer.tsx'));
  if (mime.includes('image/'))
    return lazy(
      () =>
        import('@/components/viewer/image-viewer.tsx') as Promise<{
          default: ComponentType<FileViewerProps>;
        }>,
    );
  if (mime.includes('application/pdf'))
    return lazy(
      () =>
        import('@/components/viewer/pdf-viewer.tsx') as Promise<{
          default: ComponentType<FileViewerProps>;
        }>,
    );
  return lazy(
    () =>
      import('@/components/viewer/unknown-viewer.tsx') as Promise<{
        default: ComponentType<FileViewerProps>;
      }>,
  );
  //   todo other types
};

const NasFileView = ({ data }: NasFileViewProps) => {
  const { basename, fileUrl, ocr, nasId, id: nasFileId } = data;
  const router = useRouter();
  const [width] = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  const [panelOpen, setPanelOpen] = useState(false);
  const [currentPanelId, setCurrentPanelId] = useState<string | null>(null);
  const [ViewerComponent, setViewerComponent] = useState<ViewerComponentType | null>(
    null,
  );

  const { data: fileInfo } = useFileQuery(data.fileUrl);

  const panelData = useMemo(() => {
    return [
      ...(isMobile
        ? []
        : [
            {
              id: PanelId.HISTORY,
              title: 'OCR 歷史紀錄',
              icon: <History strokeWidth={1.2} />,
              content: (
                <NasOcrViewer nasId={nasId} nasFileId={nasFileId} ocrText={ocr} />
              ),
            },
          ]),
      // {
      //   id: PanelId.INFO,
      //   title: '詳細資料',
      //   icon: <Info strokeWidth={1.2} />,
      //   content: (
      //     <FileInfoPanel
      //       data={data}
      //     />
      //   ),
      // },
    ];
  }, [isMobile]);

  useEffect(() => {
    setIsMobile(width <= 576);
  }, [width]);

  useEffect(() => {
    setViewerComponent(() => importComponent(data.mime));
  }, [data.mime]);

  if (!ViewerComponent) return null;

  return (
    <Suspense fallback={<Loading />}>
      <TooltipProvider delayDuration={0}>
        <FileHeader
          name={basename}
          panelData={panelData}
          onOpenPanel={() => setPanelOpen(true)}
          setCurrentPanelId={setCurrentPanelId}
          hasPreviousPage={router.history.length > 2}
          onBack={() => router.history.back()}
        />
        <SidePanel
          isOpen={panelOpen}
          onClose={() => setPanelOpen(false)}
          currentPanelId={currentPanelId}
          panelData={panelData}
        />
      </TooltipProvider>
      <section className={isMobile && panelOpen ? 'hidden' : ''}>
        <div className="grid grid-cols-1 lg:grid-cols-4">
          <div
            className={cn(
              'col-span-1 lg:col-span-4 mt-4',
              panelOpen && 'lg:col-span-3',
            )}
          >
            <ViewerComponent file={fileInfo?.blob} fileUrl={fileUrl} />
          </div>
        </div>
      </section>
    </Suspense>
  );
};

export default NasFileView;

import { useState } from 'react';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { useMutation, useQuery } from '@apollo/client';
import {
  NasFileOcrHistoriesDocument,
  NasFileOcrModel,
  RestoreNasFileOcrDocument,
} from '@@graphql';
import { toast } from '@/components/ui/use-toast.ts';
import { Card } from '@/components/ui/card';
import { transFormDate } from '@/utils/file';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';

interface NasOcrHistoryProps {
  nasId?: string;
  nasFileId?: string;
  onRestore: (text: string) => void;
}
export const NasOcrHistory = ({ nasId, nasFileId, onRestore }: NasOcrHistoryProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { handleError } = useErrorHandler({});

  const { data, error, loading, refetch } = useQuery(NasFileOcrHistoriesDocument, {
    variables: { nasFileId, nasId },
    fetchPolicy: 'network-only',
  });
  const [restoreNasOcrMutation] = useMutation(RestoreNasFileOcrDocument, {
    onCompleted: async data => {
      await refetch({ nasFileId, nasId });
      toast({
        title: '更新成功',
        description: 'NAS檔案 OCR 內容已更新',
      });
      setOpenDialog(false);
      onRestore(data.restoreNasFileOcr.ocrText);
    },
    onError: error => handleError(error),
  });

  const [selectedHistory, setSelectedHistory] = useState<NasFileOcrModel | null>(null);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  const histories = data?.nasFile.ocrHistories as NasFileOcrModel[];
  const handleCardClick = (history: NasFileOcrModel) => {
    setSelectedHistory(history);
    setOpenDialog(true);
  };
  const handleRestore = async () => {
    if (selectedHistory) {
      await restoreNasOcrMutation({
        variables: {
          nasId,
          nasFileId,
          nasFileOcrId: selectedHistory.id,
        },
      });
    }
  };
  return (
    <div className="mt-2 space-y-2">
      {histories.map(history => {
        return (
          <Card
            key={history.id}
            className="hover:bg-primary-100 cursor-pointer flex items-start p-6"
            onClick={() => handleCardClick(history)}
          >
            <div className="flex-shrink-0 w-8 h-8 bg-blue-500 rounded-full flex items-center justify-center text-white font-bold text-sm mr-3">
              {history.fileOcrEditorName.charAt(0).toUpperCase() || '?'}
            </div>
            <div className="flex-grow min-w-0">
              <div className="flex items-center text-sm">
                <span className="font-medium">{history.fileOcrEditorName}</span>
                <span className="mx-1 text-gray-500">•</span>
                <span className="text-gray-500">
                  {transFormDate(history.createdAt)}
                </span>
              </div>
              <p className="mt-1 text-sm text-gray-700 truncate">{history.ocrText}</p>
            </div>
          </Card>
        );
      })}
      <Dialog open={openDialog} onOpenChange={setOpenDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>OCR 歷史紀錄</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <b className="text-primary">更新者</b>
            <div>{selectedHistory?.fileOcrEditorName}</div>
            <b className="text-primary">更新時間</b>
            <div>{transFormDate(selectedHistory?.createdAt)}</div>
            <b className="text-primary">OCR 內容</b>
            <div className="max-h-[200px] overflow-y-auto">
              {selectedHistory?.ocrText}
            </div>
          </div>
          <Button onClick={handleRestore}>復原成此 OCR</Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

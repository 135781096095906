import { useEffect, useRef, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/components/ui/use-toast';
import { Link, useLocation } from '@tanstack/react-router';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { useMutation } from '@apollo/client';
import { StoreNasFileOcrDocument } from '@@graphql';
import { NasOcrHistory } from '@/components/viewer/ui/nas-ocr-history.tsx';

interface NasOcrViewerProps {
  ocrText?: string;
  nasId?: string;
  nasFileId?: string;
}

const FormSchema = z.object({
  ocrText: z
    .string()
    .min(1, {
      message: 'Ocr 文字送出至少要 1 個字',
    })
    .max(65536, {
      message: 'Ocr 文字不能超過 65536 字',
    }),
});

function NasOcrViewer({ ocrText, nasId, nasFileId }: NasOcrViewerProps) {
  const location = useLocation();
  const [expandHistoryBlock, setExpandHistoryBlock] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { handleError } = useErrorHandler({});

  const [storeNasFileOcrMutation] = useMutation(StoreNasFileOcrDocument, {
    onCompleted: async () => {
      toast({
        title: '更新成功',
        description: '檔案OCR內容已更新',
      });
    },
  });

  useEffect(() => {
    const adjustHeight = () => {
      if (textareaRef.current) {
        const textareaRect = textareaRef.current.getBoundingClientRect();
        const topPosition = textareaRect.top;
        const viewportHeight = window.innerHeight;
        const newHeight = viewportHeight - topPosition - 50;

        textareaRef.current.style.height = `${newHeight}px`;
        textareaRef.current.style.overflowY = 'auto';
      }
    };
    requestAnimationFrame(adjustHeight);
    window.addEventListener('resize', adjustHeight);

    return () => {
      window.removeEventListener('resize', adjustHeight);
    };
  }, [expandHistoryBlock]);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ocrText,
    },
  });

  const handleRestore = (restoredText: string) => {
    form.setValue('ocrText', restoredText);
    setExpandHistoryBlock(false);
  };

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      await storeNasFileOcrMutation({
        variables: {
          ocrText: data.ocrText,
          nasId,
          nasFileId,
        },
      });
      toast({
        title: '已經更新Ocr文字',
      });
    } catch (err) {
      handleError(err);
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="px-6 space-y-4 lg:px-2">
        {!expandHistoryBlock ? (
          <FormField
            control={form.control}
            name="ocrText"
            render={({ field }) => (
              <FormItem>
                <div className="flex justify-between items-center mb-2">
                  <FormLabel className="text-base font-medium">檔案OCR內容</FormLabel>
                  <Link
                    className="text-sm text-muted-foreground hover:text-primary"
                    onClick={e => {
                      e.preventDefault();
                      setExpandHistoryBlock(!expandHistoryBlock);
                    }}
                  >
                    查看 OCR 歷史紀錄
                  </Link>
                </div>
                <FormControl>
                  <Textarea
                    placeholder="目前無OCR內容，可以自行輸入"
                    className="overflow-hidden resize-none"
                    {...field}
                    ref={e => {
                      field.ref(e);
                      textareaRef.current = e;
                    }}
                  />
                </FormControl>
                <FormDescription>
                  OCR 文字是由檔案內容自動辨識出來的文字，不代表100%正確，請自行調整
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        ) : (
          <div>
            <div className="flex justify-between items-center mb-2">
              <FormLabel className="text-base font-medium">OCR 歷史紀錄</FormLabel>
              <Link
                className="text-sm text-muted-foreground hover:text-primary cursor-pointer"
                onClick={e => {
                  e.preventDefault();
                  setExpandHistoryBlock(false);
                }}
              >
                返回編輯
              </Link>
            </div>
            <NasOcrHistory
              nasId={nasId}
              nasFileId={nasFileId}
              onRestore={handleRestore}
            />
          </div>
        )}
        {!location.pathname.startsWith('/trash') && !expandHistoryBlock && (
          <div className="text-right">
            <Button type="submit">儲存</Button>
          </div>
        )}
      </form>
    </Form>
  );
}

export default NasOcrViewer;

import { useEffect, useState } from 'react';
import {
  CommandDialog,
  CommandEmpty,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { cn, getFileIcon } from '@/lib/utils';
import useDebounce from '@/hooks/useDebounce';
import { useLazyQuery } from '@apollo/client';
import { SearchFilesDocument, SearchNasFilesDocument } from '@@graphql';
import { useRouter } from '@tanstack/react-router';
import { Lock, Search } from 'lucide-react';
import { RealFileModel, User } from '@/types';
import { fileModelToRealFileModel } from '@/utils/file.ts';
import { Button } from '@/components/ui/button.tsx';
import { SearchMime, SearchScope } from '@/constants';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { Input } from '@/components/ui/input.tsx';
import useQueryString from '@/hooks/useQueryString.tsx';
import { useAuth } from '@/auth.tsx';
import { nasFileModelToRealFileModel } from '@/utils/nas-file.ts';

type SearchDialogProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

export const SearchBar = ({ open, onOpenChange }: SearchDialogProps) => {
  const user = useAuth().user as User;
  const [searchTerm, setSearchTerm] = useState('');
  const [files, setFiles] = useState<RealFileModel[]>([]);
  const [searchScope, setSearchScope] = useState<string>(SearchScope.FILE_NAME);
  const [searchMime, setSearchMime] = useState<string>(SearchMime.ALL);

  const router = useRouter();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const query = useQueryString();
  const regex = /\/nas\/([a-z0-9]+)(?:\/|$)/i;
  const path = router.state.location.pathname;
  const match = path.match(regex);
  const inNasFolder = Boolean(match);
  const nasId = inNasFolder ? match?.at(1) : null;
  const [searchFiles, { loading, data, error }] = useLazyQuery(
    inNasFolder ? SearchNasFilesDocument : SearchFilesDocument,
    {
      fetchPolicy: 'network-only',
    },
  );
  const ocrOnly = searchScope === SearchScope.OCR;
  const departmentMap = user.assignments?.reduce((result, { department }, _index) => {
    result[department.id] = department.name;
    return result;
  }, {} as { [key: string]: string });
  useEffect(() => {
    if (debouncedSearchTerm.length >= 1) {
      searchFiles({
        variables: { q: debouncedSearchTerm, ocrOnly, mime: searchMime, nasId },
      });
    }
  }, [debouncedSearchTerm, searchScope, searchMime, nasId]);

  useEffect(() => {
    if (data?.searchFiles?.items?.length || data?.searchNasFiles?.items?.length) {
      setFiles(
        inNasFolder
          ? nasFileModelToRealFileModel(data.searchNasFiles.items)
          : fileModelToRealFileModel(data.searchFiles.items, departmentMap),
      );
    }
  }, [data, nasId]);

  const handleOpenChange = (newOpen: boolean) => {
    setFiles([]);
    setSearchTerm('');
    onOpenChange(newOpen);
  };

  const handleRedirect = (fileId: string, isDir: boolean) => {
    onOpenChange(false);
    const to = inNasFolder
      ? isDir
        ? `/nas/${nasId}`
        : `/nas/${nasId}/file/${fileId}`
      : isDir
      ? `/folders/${fileId}`
      : `/files/${fileId}`;

    router.navigate({ to });
    return;
  };

  const handleSearchResults = () => {
    onOpenChange(false);

    router.navigate({
      to: inNasFolder ? `/nas/${nasId}/search` : '/search',
      search: { q: searchTerm, searchScope, mime: searchMime },
    });
    return;
  };
  return (
    <>
      <div className="mx-auto items-center gap-2 hidden md:flex">
        <Select onValueChange={setSearchScope}>
          <SelectTrigger className="w-[110px] focus:ring-transparent">
            <SelectValue placeholder="檔案名稱" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={SearchScope.FILE_NAME}>檔案名稱</SelectItem>
            <SelectItem value={SearchScope.OCR}>OCR</SelectItem>
          </SelectContent>
        </Select>
        <Input
          className="w-[300px] focus-visible:ring-transparent"
          defaultValue={searchTerm || query.q || ''}
          placeholder="請輸入關鍵字"
          onClick={() => handleOpenChange(true)}
        />
        <Select onValueChange={setSearchMime}>
          <SelectTrigger className="w-[110px] focus:ring-transparent">
            <SelectValue placeholder="類型" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={SearchMime.ALL}>所有類型</SelectItem>
            <SelectItem value={SearchMime.PDF}>PDF</SelectItem>
            <SelectItem value={SearchMime.IMAGE}>圖片</SelectItem>
            <SelectItem value={SearchMime.OFFICE}>Office 相關</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <CommandDialog open={open} onOpenChange={handleOpenChange}>
        <div className="flex items-center border-b px-3">
          <Search className="mr-2 h-6 w-6 shrink-0 opacity-50" />
          <input
            className={cn(
              'flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
            )}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="請輸入關鍵字..."
          />
        </div>
        {files.length > 0 && (
          <div className="p-2 border-b">
            <Button onClick={handleSearchResults} className="w-full">
              前往搜尋結果
            </Button>
          </div>
        )}
        <div className="flex-grow overflow-auto">
          <CommandList>
            {!loading && !error && (
              <>
                {files.length > 0 ? (
                  <>
                    {files.map(file => (
                      <CommandItem key={file.id}>
                        <div
                          className="flex w-full cursor-pointer"
                          onClick={() => handleRedirect(file.id, file.isDir)}
                        >
                          <div className="flex-1 flex flex-row justify-start gap-2">
                            {getFileIcon(file)}
                            {file.name}
                            {file.isPrivate && <Lock className="text-neutral-500" />}
                          </div>
                          <div className="text-neutral-500">{file.departmentName}</div>
                        </div>
                      </CommandItem>
                    ))}
                  </>
                ) : (
                  <CommandEmpty>沒有搜尋結果</CommandEmpty>
                )}
              </>
            )}
          </CommandList>
        </div>
      </CommandDialog>
    </>
  );
};

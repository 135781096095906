import { createFileRoute } from '@tanstack/react-router';
import { useLazyQuery } from '@apollo/client';
import { FileMimeEnum, SearchNasFilesDocument } from '@@graphql';
import { RealFileModel } from '@/types';
import FolderView from '@/pages/folderView.tsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SearchScope, TAKE_PER_PAGE } from '@/constants';
import InfiniteScrollWrapper from '@/components/infinite-scroll-wrapper.tsx';
import { nasFileModelToRealFileModel } from '@/utils/nas-file.ts';

type SearchProps = {
  q?: string;
  searchScope: SearchScope;
  mime: FileMimeEnum;
};

export const Route = createFileRoute('/_layout/nas/$nasId/search')({
  component: Search,
});

function Search() {
  const { q, searchScope, mime } = Route.useSearch() as SearchProps;
  const { nasId } = Route.useParams();
  const currentPageRef = useRef<number>(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [files, setFiles] = useState<RealFileModel[]>([]);
  const ocrOnly = searchScope === SearchScope.OCR;

  const [fetchNasFiles, { loading, error, data }] = useLazyQuery(
    SearchNasFilesDocument,
    {
      fetchPolicy: 'cache-first',
    },
  );

  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      const result = await fetchNasFiles({
        variables: {
          mime,
          q,
          ocrOnly,
          nasId,
          page: currentPageRef.current,
          take: TAKE_PER_PAGE,
        },
      });
      if (!ignore && result.data) {
        setFiles(nasFileModelToRealFileModel(result.data.searchNasFiles.items));
        currentPageRef.current = 2;
      }
    };
    currentPageRef.current = 1;
    setIsLoadingMore(false);

    fetchData();

    return () => {
      ignore = true;
    };
  }, [q, mime, searchScope]);

  const loadMore = useCallback(async () => {
    if (isLoadingMore || !data?.searchNasFiles.pageInfo.hasNextPage) {
      return null;
    }
    setIsLoadingMore(true);
    const result = await fetchNasFiles({
      variables: {
        q,
        page: currentPageRef.current,
        nasId,
        take: TAKE_PER_PAGE,
        mime,
        ocrOnly,
      },
    });
    if (result.data?.searchNasFiles.items.length) {
      const newFiles = nasFileModelToRealFileModel(result.data.searchNasFiles.items);
      setFiles(prev => [...prev, ...newFiles]);
      currentPageRef.current += 1;
    }
    setIsLoadingMore(false);
    return result;
  }, [
    data?.searchNasFiles.pageInfo.hasNextPage,
    searchScope,
    mime,
    fetchNasFiles,
    q,
    isLoadingMore,
  ]);

  const refetchFolders = useCallback(async () => {
    currentPageRef.current = 1;
    setIsLoadingMore(false);
    setFiles([]);
    const result = await fetchNasFiles({
      variables: {
        q,
        page: 1,
        take: TAKE_PER_PAGE,
        mime,
        nasId,
        ocrOnly,
      },
    });
    if (result.data) {
      setFiles(nasFileModelToRealFileModel(result.data.searchNasFiles.items));
      currentPageRef.current = 2;
    }
  }, [mime, searchScope, q, nasId]);

  if (loading && currentPageRef.current === 1) return null;
  if (error) return <div>Error: {error.message}</div>;

  const isDepartment = false;
  const folderName = '搜尋結果';

  return (
    <InfiniteScrollWrapper
      hasMore={data?.searchNasFiles?.pageInfo.hasNextPage || false}
      loadMore={loadMore}
      isLoading={isLoadingMore && currentPageRef.current !== 1}
    >
      <FolderView
        columns={['selector', 'name', 'size', 'departmentName', 'updatedAt']}
        folderName={folderName}
        nasId={nasId}
        isDepartment={isDepartment}
        breadcrumbs={[]}
        refetchFolder={refetchFolders}
        files={files}
      />
    </InfiniteScrollWrapper>
  );
}

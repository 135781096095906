import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Settings, UserRoundPlus } from 'lucide-react';
import { Label } from './ui/label';
import { Input } from './ui/input';
import { DialogActionType } from '@/constants';
import {
  CreateUserDocument,
  UpdateUserDocument,
  UsersQuery,
  UsersQueryVariables,
} from '@@graphql';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { FormEvent, useState } from 'react';
import { CreateUserSchema } from '@/zodSchema/create-user.ts';
import { UpdateUserSchema } from '@/zodSchema/update-user.ts';
import { toast } from '@/components/ui/use-toast';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { Switch } from '@/components/ui/switch.tsx';

type UserDialogProps = {
  type: DialogActionType;
  id?: string;
  department?: string;
  name?: string;
  email?: string;
  enable?: boolean;
  refetch?: (
    variables?: Partial<UsersQueryVariables>,
  ) => Promise<ApolloQueryResult<UsersQuery>>;
  isAdmin?: boolean;
  role?: ROLE;
};

interface UserFormData {
  name: string;
  email: string;
}

enum ROLE {
  ADMIN = 'Admin',
  USER = 'User',
}

const UserDialog = ({
  type,
  department,
  name,
  email,
  refetch,
  isAdmin = false,
  id: userId,
}: UserDialogProps) => {
  const [open, setOpen] = useState(false);
  const [isAdminState, setIsAdminState] = useState<boolean>(isAdmin);
  const errorObject = {
    name: '',
    email: '',
  };
  const { errors, handleError, resetErrors } = useErrorHandler(errorObject);

  const [createUserMutation] = useMutation(CreateUserDocument, {
    onCompleted: async () => {
      refetch && (await refetch());
    },
  });

  const [updateUserMutation] = useMutation(UpdateUserDocument, {
    onCompleted: async () => {
      refetch && (await refetch());
    },
  });

  const createUser = async (formData: UserFormData) => {
    await createUserMutation({
      variables: {
        ...formData,
      },
    });
  };

  const updateUser = async (id: string, formData: UserFormData) => {
    await updateUserMutation({
      variables: {
        updateUserId: id,
        ...formData,
      },
    });
  };
  const onSubmitCreateOrUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resetErrors();
    const data = new FormData(e.currentTarget);
    const nameFieldValue = data.get('name');
    const emailFieldValue = data.get('email');
    const formData = {
      name: nameFieldValue!.toString().trimEnd(),
      email: emailFieldValue!.toString().trimEnd(),
      isAdmin: isAdminState,
    };
    try {
      if (DialogActionType.CREATE === type) {
        CreateUserSchema.parse(formData);
        await createUser(formData);
        toast({ title: '新增使用者成功' });
      } else {
        UpdateUserSchema.parse(formData);
        userId && (await updateUser(userId, formData));
        toast({ title: '編輯使用者成功' });
      }
      setOpen(false);
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {type === DialogActionType.CREATE ? (
          <Button className="gap-1 font-normal text-primary" variant="ghost">
            <UserRoundPlus size={20} strokeWidth={1.5} /> 新增使用者
          </Button>
        ) : (
          <Button
            className="size-8 text-typography-secondary"
            variant="ghost"
            size="icon"
          >
            <Settings size={20} strokeWidth={1.5} />
          </Button>
        )}
      </DialogTrigger>
      <DialogContent
        className={`sm:max-w-[425px] ${type === DialogActionType.EDIT && 'lg:max-w-[550px]'}`}
      >
        <form onSubmit={onSubmitCreateOrUpdate}>
          <DialogHeader>
            <DialogTitle>
              {type === DialogActionType.CREATE ? '新增使用者' : '編輯使用者'}
            </DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid items-center grid-cols-4 gap-4">
              <Label htmlFor="name" className="font-normal text-right">
                使用者名稱
              </Label>
              <Input id="name" name="name" className="col-span-3" defaultValue={name} />
              {errors.name && <div className="text-red-500">{errors.name}</div>}
            </div>
            {type === DialogActionType.EDIT && (
              <div className="grid items-center grid-cols-4 gap-4">
                <Label htmlFor="department" className="font-normal text-right">
                  部門名稱
                </Label>
                <Input
                  id="name"
                  className="col-span-3"
                  disabled
                  defaultValue={department}
                />
              </div>
            )}
            <div className="grid items-center grid-cols-4 gap-4">
              <Label htmlFor="email" className="font-normal text-right">
                電子郵件
              </Label>
              <Input
                id="email"
                name="email"
                type="email"
                className="col-span-3"
                defaultValue={email}
                disabled={isAdmin}
              />
              {errors.email && <div className="text-red-500">{errors.email}</div>}
            </div>
            {type === DialogActionType.EDIT && (
              <div className="grid items-center grid-cols-4 gap-4">
                <Label htmlFor="isAdmin" className="font-normal text-right">
                  公司管理者
                </Label>
                <Switch
                  disabled={isAdmin}
                  id="isAdmin"
                  checked={isAdminState}
                  onCheckedChange={setIsAdminState}
                />
              </div>
            )}
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" type="button">
                取消
              </Button>
            </DialogClose>
            <Button type="submit">儲存</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UserDialog;

import Header from '@/components/layout/header';
import Sidebar from '@/components/layout/sidebar';
import { ReactNode } from '@tanstack/react-router';
import { useQuery } from '@apollo/client';
import { CompanyConfigDocument, HomeDocument, NasListDocument } from '@@graphql';
import { useStore } from '@/store';
import { useEffect } from 'react';
import Loading from '../loading';
import { Department } from '@/store/home.ts';
import { useNasStore } from '@/store/nas';

export const Layout = ({ children }: { children: ReactNode }) => {
  const {
    data: homeData,
    error: homeError,
    loading: homeLoading,
  } = useQuery(HomeDocument, {
    fetchPolicy: 'network-only',
  });
  const {
    data: companyData,
    error: companyError,
    loading: companyLoading,
  } = useQuery(CompanyConfigDocument, {
    fetchPolicy: 'network-only',
  });
  const loading = homeLoading || companyLoading;
  const error = homeError || companyError;
  const { data: nasListData, loading: nasListLoading } = useQuery(NasListDocument, {
    fetchPolicy: 'network-only',
  });

  const setNasList = useNasStore(state => state.setNasList);
  const setIsNasListLoading = useNasStore(state => state.setIsNasListLoading);

  useEffect(() => {
    setIsNasListLoading(nasListLoading);
    const nasList = nasListData?.nasList;
    if (nasList) {
      setNasList(nasList);
    }
  }, [nasListData?.nasList, setNasList, setIsNasListLoading, nasListLoading]);

  const setDepartmentsStore = useStore.home(state => state.setDepartments);
  const setCompanyConfig = useStore.companyConfig(state => state.setConfig);

  useEffect(() => {
    if (companyData?.company && homeData?.folders) {
      setCompanyConfig({ ...companyData.company });
      const departments = homeData.folders.items.map(
        ({ id, departmentId, name, scope }: Department) => ({
          id,
          departmentId,
          name,
          scope,
        }),
      );
      setDepartmentsStore(departments);
    }
  }, [homeData, setDepartmentsStore, companyData, setCompanyConfig]);

  if (loading) return <Loading />;
  if (error) return <div>Error: {error.message}</div>;
  return (
    <>
      <Header />
      <div className="flex h-screen border-collapse overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-auto pt-16 bg-background pb-1">
          {children}
        </main>
      </div>
    </>
  );
};

import {
  ColumnDef,
  ColumnFiltersState,
  RowSelectionState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { create } from 'zustand';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import FileGridView from './layout/file-grid-view';
import { useRouterState } from '@tanstack/react-router';
import usePreviousLocation from '@/hooks/usePreviousLocation';

interface DataTableProps<TData, TValue> {
  className?: string;
  rowProps?: (row: any) => NonNullable<unknown>;
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  perPage?: number;
  isGridView?: boolean;
  enableMultiRowSelection?: boolean;
}

interface TableStore {
  rowSelection: RowSelectionState;
  setRowSelection: (rowSelection: any) => void;
  selectedFileIds: string[];
  setSelectedFileIds: (selectedFileIds: string[]) => void;
  table: any;
  setTable: (table: any) => void;
}

export const useTableStore = create<TableStore>(set => ({
  rowSelection: {},
  selectedFileIds: [],
  table: {},
  setRowSelection: (fn: (prev: RowSelectionState) => any) => {
    return set(state => {
      const rowSelectionData = fn(state.rowSelection);
      return {
        rowSelection: rowSelectionData,
        selectedFileIds: Object.keys(rowSelectionData),
      };
    });
  },
  setSelectedFileIds: (selectedFileIds: string[]) => {
    set({ selectedFileIds });
  },
  setTable: (table: any) => {
    return set({ table });
  },
}));

export function DataTable<TData, TValue>({
  className,
  rowProps,
  columns,
  data,
  perPage = 300,
  isGridView,
  enableMultiRowSelection = true,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: perPage,
  });

  const router = useRouterState();

  const { setTable, rowSelection, setRowSelection } = useTableStore(state => state);

  const table = useReactTable({
    enableMultiRowSelection,
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getRowId: (row: any) => row.id,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination,
    },
  });

  const prevLocation = usePreviousLocation();

  useEffect(() => {
    if (
      prevLocation &&
      !prevLocation?.pathname.startsWith('/files') &&
      !router.location.pathname.startsWith('/files')
    ) {
      table.toggleAllRowsSelected(false);
    }
  }, [prevLocation, router.location.pathname, table]);

  useEffect(() => {
    setTable(table);
  }, [setTable, table]);

  if (isGridView) {
    return <FileGridView rows={table.getRowModel().rows} rowProps={rowProps} />;
  }

  return (
    <div className={cn('w-full rounded-md border bg-white', className)}>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                {...(rowProps && rowProps(row))}
              >
                {row.getVisibleCells().map(cell => (
                  <TableCell
                    key={cell.id}
                    className={cn(cell.column.id === 'selector' && 'first:w-[15px]')}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow className="hover:bg-transparent last:border-b-0">
              <TableCell colSpan={columns.length} className="h-24 text-center">
                目前沒有資料
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

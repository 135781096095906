import { useFileQuery } from '@/tanstack';
import * as React from 'react';
import { Button } from './button';
import { Download } from '../icons';

const downloadFile = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

interface FileDownloadProps {
  fileUrl: string;
  onFinish?: () => void;
}

const FileDownload: React.FC<FileDownloadProps> = ({ fileUrl, onFinish }) => {
  const { refetch, data, isLoading, isError, error } = useFileQuery(fileUrl, false);
  const handleDownload = () => {
    refetch();
  };

  React.useEffect(() => {
    if (data) {
      downloadFile(data.blob, data.fileName);
      onFinish?.();
    }
  }, [data, onFinish]);

  return (
    <Button
      className="size-8 text-typography-secondary hover:bg-primary-100 hover:text-typography-secondary"
      variant="ghost"
      size="icon"
      data-action-button-title="下載"
      disabled={isLoading}
      onClick={handleDownload}
    >
      {isLoading ? '下載中...' : <Download />}
      {isError && (
        <p style={{ color: 'red' }}>
          錯誤: {error instanceof Error ? error.message : '未知錯誤'}
        </p>
      )}
    </Button>
  );
};

export default FileDownload;

import { RegisteredRouter } from '@tanstack/react-router';

interface goToFolderProps {
  router: RegisteredRouter;
  folderId: string;
}

interface goToNasFolderProps {
  router: RegisteredRouter;
  nasId: string;
  nasFileId: string;
}

interface goToFileProps {
  router: RegisteredRouter;
  fileId: string;
}

interface goToNasFileProps {
  router: RegisteredRouter;
  nasId: string;
  nasFileId: string;
}

export const goToFolder = async ({ router, folderId }: goToFolderProps) => {
  await router.navigate({
    to: `/folders/$folderId`,
    params: {
      folderId,
    },
  });
};

export const goToFile = async ({ router, fileId }: goToFileProps) => {
  await router.navigate({
    to: `/files/$fileId`,
    params: {
      fileId,
    },
  });
};

export const goToNasFolder = async ({
  router,
  nasId,
  nasFileId,
}: goToNasFolderProps) => {
  await router.navigate({
    to: '/nas/$nasId',
    params: {
      nasId,
    },
    search: {
      nasFileId,
    },
  });
};

export const goToNasFile = async ({ router, nasId, nasFileId }: goToNasFileProps) => {
  await router.navigate({
    to: '/nas/$nasId/file/$nasFileId',
    params: {
      nasId,
      nasFileId,
    },
  });
};

export const gotoTrashFile = async ({ router, fileId }: goToFileProps) => {
  await router.navigate({
    to: `/trash/$fileId`,
    params: {
      fileId,
    },
  });
};

import { create } from 'zustand';
import { NasModel } from '@@graphql';

interface NasState {
  nasList: NasModel[];
  isNasListLoading: boolean;
  setIsNasListLoading: (isLoading: boolean) => void;
  setNasList: (nasList: NasModel[]) => void;
}

export const useNasStore = create<NasState>(set => ({
  nasList: [],
  isNasListLoading: false,
  setIsNasListLoading: isLoading => set({ isNasListLoading: isLoading }),
  setNasList: nasList => set({ nasList }),
}));
